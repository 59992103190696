import { useLocalStore } from "@dladio/hooks";
import { STORE } from "app/consts";
import IconAlert from "components/common/IconAlert";
import React from "react";
import { Placeholder, Row } from "react-bootstrap";
import ParamSelector from './components/ParamSelector/ParamSelector';
import SalesEmployeeSalesSummary from "./components/SalesEmployeeSalesSummary/SalesEmployeeSalesSummary";

const SalesDashboardMgrView = () => {

    const { model: localModel } = useLocalStore(STORE.SALES_DASH)
    const user = _.get(localModel, 'user')

    return (
        <>
            <Row className={"g-3"}>
                <ParamSelector />
            </Row>
            {user ?
                (
                    <Row>
                        <SalesEmployeeSalesSummary />
                    </Row>
                )
                :
                (
                    <Row className={"g-3"}>
                        <IconAlert variant={'warning'}>
                            <p className="mb-0">Please select a sales employee to continue!</p>
                        </IconAlert>

                    </Row>
                )
            }
        </>
    );

}

export default SalesDashboardMgrView