import React, {useContext} from 'react';
import {Card, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AppContext, {KanbanContext} from 'context/Context';
import {dispatchAction} from "@dladio/app-bridge";

const TaskDropMenu = ({id}) => {
    const {kanbanDispatch} = useContext(KanbanContext);

    const {
        config: {isRTL}
    } = useContext(AppContext);

    const handleRemoveTaskCard = () => {
        kanbanDispatch({
            type: 'REMOVE_TASK_CARD',
            payload: {id}
        });
    };

    return (
        <Dropdown
            onClick={e => e.stopPropagation()}
            align="end"
            className="font-sans-serif"
        >
            <Dropdown.Toggle
                variant="falcon-default"
                size="sm"
                className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
            >
                <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2"/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0" align={isRTL ? 'start' : 'end'}>
                <Dropdown.Item href="#!">Add Card</Dropdown.Item>
                <Dropdown.Item href="#!">Edit</Dropdown.Item>
                <Dropdown.Item href="#!">Copy link</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item onClick={handleRemoveTaskCard} className="text-danger">
                    Remove
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const TaskCard = ({data}) => {

    const onClickTaskCard = (cartCode) => {
        dispatchAction({
            action: 'navigate',
            payload: `page/052258269e?id=${cartCode}`
        })
    }

    return (
        <div className="kanban-item p-0 cursor-pointer hover-actions-trigger" onClick={() => {
            onClickTaskCard(data?.CardCode)
        }}>

            <Card
                className="kanban-item-card p-0 hover-actions-trigger"
            >
                <div className={'ps-3 m-0'}>
                    <div>
                        <p className={'fs--1 text-md-center'}></p> {data?.CardCode}
                    </div>
                    <div>
                        <p className={'fs--1'}>{data?.CardName}</p>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default TaskCard;
