import React from 'react';
import { Row } from 'react-bootstrap';
import MyCustomerList from './components/MyCustomerList';
import ParamSelector from './components/ParamSelector/ParamSelector';

const SalesStrikeDashboard = () => {
    return (
        <>
            <Row className={"g-3"}>
                <ParamSelector />
            </Row>

        </>
    )
}

export default SalesStrikeDashboard