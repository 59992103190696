import { useAppBridgeSelector } from "@dladio/app-bridge"
import { useLocalStore, useSafeQuery } from "@dladio/hooks"
import { useExecMethodApiQuery } from "@dladio/service"
import { EXEC_QUERY_METHOD } from "@dladio/service/dist/const/erp-method.consts"
import { STORE } from "app/consts"
import _ from "lodash"
import moment from "moment"
import { Col, Row } from "react-bootstrap"
import WidgetSectionTitle from "widgets/WidgetSectionTitle"
import StatsCard from "../StatsCard"

const DashboardHeader = () => {

    const { model } = useAppBridgeSelector()
    const { model: localModel } = useLocalStore(STORE.SALES_DASH)

    const date = _.get(localModel, ['selectedYearMonth', 'date'])
    const user = _.get(model, ['ui', 'currentUser', 'sap_id'])

    // const { data } = useSafeQuery(useExecMethodApiQuery, {
    //     method: EXEC_QUERY_METHOD, body: {
    //         name: "75dcf219e7",
    //         user: user
    //     },
    // });

    return (
        <>
            <Row className={"g-3 mb-3"}>
                <Col lg={12}>
                    <WidgetSectionTitle
                        icon="list"
                        title={"Overall Performance"}
                        r
                        subtitle={"for " + date}
                        transform="shrink-2"
                        className="mb-2"
                    />
                </Col>

                <Col lg={3}>
                    <StatsCard varient="danger" title={"Overdue Activities"}
                        value={1} />
                </Col>
                <Col lg={3}>
                    <StatsCard varient="primary" title={"Due Activities"}
                        value={1} />
                </Col>
                <Col lg={3}>
                    <StatsCard varient="secondary" title={"Future Activities"}
                        value={1} />
                </Col>
                <Col lg={3}>
                    <StatsCard varient="success" title={"Completed Acivities Today"}
                        value={1} />
                </Col>
            </Row>

            <Row className={"g-3 mb-3"}>
                <Col lg={12}>
                    <WidgetSectionTitle
                        icon="list"
                        title={"Call Cycle Performance"}
                        r
                        subtitle={"for " + date}
                        transform="shrink-2"
                        className="mb-2"
                    />
                </Col>

                <Col lg={3}>
                    <StatsCard varient="danger" title={"Overdue Activities"}
                        value={1} />
                </Col>
                <Col lg={3}>
                    <StatsCard varient="primary" title={"Due Activities"}
                        value={1} />
                </Col>
                <Col lg={3}>
                    <StatsCard varient="secondary" title={"Future Activities"}
                        value={1} />
                </Col>
                <Col lg={3}>
                    <StatsCard varient="success" title={"Completed Acivities Today"}
                        value={1} />
                </Col>
            </Row>
        </>
    )
}

export default DashboardHeader