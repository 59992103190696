// Key Account Management Page
const GET_TOTAL_BP_COUNT = "1679843b56"
const GET_CURRENT_BP_COUNT_FOR_THE_MONTH = "b0f006c566"
const GET_CURRENT_BP_COUNT_FOR_LAST_QUARTER = "6c5ab22972"
const GET_CURRENT_BP_COUNT_FOR_THE_YEAR = "16186a6a4d"
const GET_OVERDUE_TASK_COUNT = "53581dccdf"
const GET_BP_DOLLAR_VALUE = "7c9a35e605"
const GET_LEAD_CONVERSATION = "4edd098b09"
const GET_CUSTOMER_GRADING_SUMMARY = "b257ce97f3"
const GET_SALES_VALUE = "c2197e9e07"
const GET_YTD_SALES_VALUE = "e55e5b4477"
const GET_AVERAGE_ORDER_VALUE = "b3cd3d205c"
const GET_SALES_VS_GROSS_PROFIT = "5ba2fd48dc"
const GET_SALES_COMPARISON = "5647afcaad"
const GET_SALES_BIRD_EYE_SALES_TARGET_AND_ACHIEVEMENT_BY_BP = "6cb12a16ee"
const GET_SALES_BIRD_EYE_SALES_TARGET_AND_ACHIEVEMENT_BY_SEGMENT = "9c4b0b8d09"


export default {
    // Key Account Management Page
    GET_BP_DOLLAR_VALUE,
    GET_CURRENT_BP_COUNT_FOR_LAST_QUARTER,
    GET_CURRENT_BP_COUNT_FOR_THE_YEAR,
    GET_CURRENT_BP_COUNT_FOR_THE_MONTH,
    GET_OVERDUE_TASK_COUNT,
    GET_CUSTOMER_GRADING_SUMMARY,
    GET_TOTAL_BP_COUNT,
    GET_SALES_VALUE,
    GET_YTD_SALES_VALUE,
    GET_AVERAGE_ORDER_VALUE,
    GET_SALES_VS_GROSS_PROFIT,
    GET_SALES_COMPARISON,
    //lead
    GET_LEAD_CONVERSATION
}