import React from 'react';
import { Row } from 'react-bootstrap';
import AnnualSummary from './AnnualSummary';
import BpCategoryWiseSalesStatsForYear from './BpCategoryWiseSalesStatsForYear';
import CurrentQuarterSummary from './CurrentQuarterSummary';
import ParamSelector from './ParamSelector';

const SalesBirdEyeDashboard = () => {

    return (
        <>
            <Row className={"g-3"}>
                <ParamSelector />
            </Row>
            <Row>
                <CurrentQuarterSummary />
            </Row>

            <Row>
                <AnnualSummary />
            </Row>

            <Row>
                <BpCategoryWiseSalesStatsForYear />
            </Row>
        </>
    );
}

export default SalesBirdEyeDashboard