import React from 'react';
import PropTypes from 'prop-types';
import {getColor} from 'helpers/utils';
import {BarChart} from 'echarts/charts';
import * as echarts from 'echarts/core';
import {useSafeQuery} from "@dladio/hooks";
import {CanvasRenderer} from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {tooltipFormatter} from 'helpers/echart-utils';
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import FalconComponentCard from 'components/common/FalconComponentCard';
import {useExecMethodApiQuery} from "@dladio/service";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    BarChart,
    CanvasRenderer,
    LegendComponent
]);

const Chart = ({query}) => {

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: query,
        },
    });

    let resolvingData = _.get(data, "message") ?? []

    const getOption = () => ({
        tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            backgroundColor: getColor('100'),
            borderColor: getColor('300'),
            textStyle: {color: getColor('dark')},
            borderWidth: 1,
            formatter: tooltipFormatter,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        },
        xAxis: {
            type: 'category',
            data: resolvingData?.map((line) => line.key),
            axisLine: {
                lineStyle: {
                    color: getColor('300'),
                    type: 'solid'
                }
            },
            axisTick: {show: false},
            axisLabel: {
                color: getColor('400'),
                formatter: value => value.substring(0, 3),
                margin: 15
            },
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                show: true,
                color: getColor('400'),
                margin: 15
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: getColor('200')
                }
            },
            axisTick: {show: false},
            axisLine: {show: false},
            min: 600
        },
        series: [
            {
                type: 'bar',
                name: 'Total',
                data: resolvingData?.map((line) => line.value),
                lineStyle: {color: getColor('primary')},
                itemStyle: {
                    color: getColor('primary'),
                    borderRadius: [3, 3, 0, 0]
                },
                showSymbol: false,
                symbol: 'circle',
                smooth: false,
                emphasis: {
                    scale: true
                }
            }
        ],
        grid: {right: '3%', left: '10%', bottom: '10%', top: '5%'}
    });
    return (
        <ReactEChartsCore
            echarts={echarts}
            option={getOption()}
            style={{height: '18.75rem'}}
        />
    );
}

const BasicBarChart = ({query}) => {
    return (
        <FalconComponentCard className="h-100">
            <Chart query={query}/>
        </FalconComponentCard>
    );
};

BasicBarChart.propTypes = {
    title: PropTypes.string
};

export default BasicBarChart;
