import * as echarts from 'echarts/core';
import {getColor, rgbaColor} from 'helpers/utils';
import ReactEChartsCore from "echarts-for-react/lib/core";
import {Card} from 'react-bootstrap'
import {useEffect, useRef} from "react";
import {useAppBridgeSelector} from "@dladio/app-bridge";
import {useLocalStore, useSafeQuery} from "@dladio/hooks";
import {STORE} from "../../../../consts";
import {useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import _ from "lodash";
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";

const OverallSalesDistributionYearly = () => {

    const chartRef = useRef(null)
    const {model} = useAppBridgeSelector()
    const {model: localModel} = useLocalStore(STORE.SALES_DASH)

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "ace802b277", //62a19769cf
            quarter: _.get(localModel, ['selectedYearMonth', 'quarter']),
            year: _.get(localModel, ['selectedYearMonth', 'year']),
            user: _.get(model, ['ui', 'currentUser', 'sap_id'])
        },
    });

    let chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    const getOption = () => ({
        legend: {
            left: 'left',
            textStyle: {
                color: getColor('gray-600')
            }
        },
        series: [
            {
                type: 'pie',
                radius: window.innerWidth < 530 ? '45%' : '60%',
                label: {
                    color: getColor('gray-700')
                },
                center: ['50%', '55%'],
                data: [
                    {
                        value: _.get(chartData, "oldAchievement") || 0,
                        name: 'Existing Business',
                        itemStyle: {
                            color: getColor('primary')
                        }
                    },
                    {
                        value: _.get(chartData, "newAchievement") || 0,
                        name: 'New Business',
                        itemStyle: {
                            color: getColor('danger')
                        }
                    }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: rgbaColor(getColor('gray-600'), 0.5)
                    }
                }
            }
        ],
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            textStyle: {color: getColor('dark')},
            borderWidth: 1,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        }
    });

    const updateDimensions = () => {
        if (window.innerWidth < 530) {
            chartRef.current.getEchartsInstance().setOption({
                series: [{radius: '45%'}]
            });
        } else
            chartRef.current.getEchartsInstance().setOption({
                series: [{radius: '60%'}]
            });
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <Card>
            <FalconCardHeader
                title="Achievement Split (New vs Existing) - (Wholesale Only)"
                titleTag="h6"
                className="border-200 border-bottom py-2"
            />
            <Card.Body>
                <ReactEChartsCore
                    echarts={echarts}
                    option={getOption()}
                    ref={chartRef}
                    style={{height: '20rem'}}
                />
            </Card.Body>
        </Card>
    )
}

export default OverallSalesDistributionYearly