import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {LineChart} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components';
import {getColor, getPastDates, rgbaColor} from 'helpers/utils';
import dayjs from 'dayjs';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    LegendComponent
]);

const tooltipFormatter = params => {
    return params
        .map(
            ({value, borderColor}, index) =>
                `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>
              <span class='text-600'>${
                    index === 0 ? 'Last Year' : 'Current Year'
                }: ${value}</span>`
        )
        .join('<br/>');
};

const getOptions = ({prevYear, currYear, labels}) => ({
    color: getColor('100'),
    tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: getColor('100'),
        borderColor: getColor('100'),
        textStyle: {color: getColor('dark')},
        borderWidth: 1,
        formatter: tooltipFormatter,
        transitionDuration: 0
    },
    legend: {
        data: ['lastMonth', 'previousYear'],
        show: false
    },
    xAxis: {
        type: 'category',
        data: labels,
        boundaryGap: false,
        axisPointer: {
            lineStyle: {
                color: getColor('300'),
                type: 'dashed'
            }
        },
        splitLine: {show: false},
        axisLine: {
            lineStyle: {
                color: rgbaColor('#000', 0.01),
                type: 'dashed'
            }
        },
        axisTick: {show: false},
        axisLabel: {
            color: getColor('400'),
            margin: 15,
        }
    },
    yAxis: {
        type: 'value',
        axisPointer: {show: false},
        splitLine: {
            lineStyle: {
                color: getColor('300'),
                type: 'dashed'
            }
        },
        boundaryGap: false,
        axisLabel: {
            show: true,
            color: getColor('400'),
            margin: 15
        },
        axisTick: {show: false},
        axisLine: {show: false}
    },
    series: [
        {
            name: 'lastMonth',
            type: 'line',
            data: prevYear,
            lineStyle: {color: getColor('primary')},
            itemStyle: {
                borderColor: getColor('primary'),
                borderWidth: 2,
                backgroundColor: 'transparent'
            },
            symbol: 'circle',
            symbolSize: 10,
            emphasis: {
                scale: true
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: rgbaColor(getColor('primary'), 0.2)
                        },
                        {
                            offset: 1,
                            color: rgbaColor(getColor('primary'), 0)
                        }
                    ]
                }
            }
        },
        {
            name: 'previousYear',
            type: 'line',
            data: currYear,
            lineStyle: {color: rgbaColor(getColor('warning'), 0.3)},
            itemStyle: {
                borderColor: rgbaColor(getColor('warning'), 0.6),
                borderWidth: 2
            },
            symbol: 'circle',
            symbolSize: 10,
            emphasis: {
                scale: true
            }
        }
    ],
    grid: {right: '30px', left: '55px', bottom: '16%', top: '5%'}
});

const SalesComparisonChart = forwardRef(({prevYear, currYear, labels}, ref) => {
    return (
        <ReactEChartsCore
            style={{height: "100%"}}
            ref={ref}
            echarts={echarts}
            option={getOptions({prevYear, currYear, labels})}/>
    );
});

SalesComparisonChart.propTypes = {
    data: PropTypes.shape({
        lastMonth: PropTypes.array,
        previousYear: PropTypes.array
    })
};

export default SalesComparisonChart;
