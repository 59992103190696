import { useDerive } from "@dladio/service";
import { EXEC_QUERY_METHOD } from "@dladio/service/dist/const/erp-method.consts";
import FalconCardHeader from "components/common/FalconCardHeader";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from "react-bootstrap";

const BasicBarChart = ({title, body, data = () => ({})}) => {

    const {data: queryData} = body ? useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD,
            body: body
        },
        path: 'message'
    }) : {};

    // const barChartData = data && queryData ? data(queryData?.message) : ()=>({})
    // console.log(data(queryData?.message), 'queryData')

    const getOptions = () => ({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            textStyle: {color: getColor('dark')},
            borderWidth: 1,
            transitionDuration: 0,
            formatter: tooltipFormatter
        },
        xAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: getColor('gray-300'),
                    type: 'solid'
                }
            },
            axisLabel: {
                color: getColor('gray-500')
            },
            axisTick: {show: true},
            splitLine: {show: false},
            data: [
                "Bunnings",
                "Domestic Agent",
                "Domestic ",
                "International Distributor",
                "Online Store",
                "Wholesale"
            ]
        },
        yAxis: {
            axisPointer: {type: 'none'},
            axisTick: 'none',
            splitLine: {
                lineStyle: {
                    color: getColor('300'),
                    type: 'dashed'
                }
            },
            axisLine: {show: false},
            axisLabel: {
                color: getColor('400'),
                formatter: value => `${value} hr`
            }
        },
        series: [
            {
                name: 'Achievement',
                type: 'bar',
                barWidth: '20%',
                barGap: '10%',
                label: {show: false},
                // itemStyle: {
                //     color: "rgba(198, 12, 12, 1)"
                // },
                z: 10,
                "data": [
                    0,
                    0,
                    0,
                    0,
                    0,
                    171055
                ]
            },
            {
                name: 'Achievement',
                type: 'bar',
                barWidth: '20%',
                barGap: '10%',
                label: {show: false},
                itemStyle: {
                    color: "rgba(198, 12, 12, 1)"
                },
                z: 10,
                "data": [
                    108610.51,
                    98113,
                    537955.91,
                    145404.16,
                    213578.5,
                    378907.92
                ]
            }
        ]
    });

    return (
        <Card>
            <FalconCardHeader
                title={title}
                titleTag="h6"
                className="border-200 border-bottom py-2"/>
            <Card.Body className={'p-0 m-0'}>
                <ReactEChartsCore
                    lazyUpdate={true}
                    echarts={echarts}
                    option={data(queryData?.message)}
                    style={{minHeight: '30.75rem'}}
                />
            </Card.Body>
        </Card>
    );
};

BasicBarChart.propTypes = {
    title: PropTypes.string
};

export default BasicBarChart;
