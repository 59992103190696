import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {
    ArSummary,
    BpGroupWiseProfitability,
    BpStats,
    CustomerGradingSummary,
    CustomerRetentionSymmary,
    Greetings,
    SalesComparison,
    SalesStat,
    SalesTarget,
    TrafficSource
} from "./components";
import {WidgetSectionTitle} from "../components";
import {salesStat, totalSale} from "./components/data";

const KeyAccountManagement = () => {
    return (
        <>
            <Greetings/>
            <Row className="g-3 mb-3">
                <Col xxl={9}>
                    <BpStats/>
                    <CustomerRetentionSymmary/>
                </Col>
                <Col xxl={3}>
                    <CustomerGradingSummary/>
                </Col>
            </Row>

            <WidgetSectionTitle
                className={"my-4 mt-5"}
                subtitle={"Sales are activities related to selling or the number of goods sold in a given targeted time period."}
                title={"Sales"}/>

            {/* <SalesAnalytics/> */}

            <Row className={"g-3 mt-1"}>
                <Col xxl={12}>
                    <SalesStat data={salesStat}/>
                </Col>
                <Col md={12} xxl={8}>
                    <ArSummary/>
                </Col>
                <Col lg={6} xxl={4}>
                    <BpGroupWiseProfitability/>
                </Col>
                <Col lg={6}>
                    <SalesComparison data={totalSale}/>
                </Col>
                <Col xxl={6}>
                    <TrafficSource/>
                </Col>
                <Col xxl={12}>
                    <SalesTarget/>
                </Col>
            </Row>
        </>);
};

export default KeyAccountManagement;
