import {Col, Row, Table} from "react-bootstrap";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../../common/advance-table/AdvanceTableSearchBox";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";



    const columns = [
        {
            accessor: 'Month',
            Header: 'Month'
        },
        {
            accessor: 'SalesTarget',
            Header: 'Sales Target'
        },
        {
            accessor: 'AmountAchieved',
            Header: 'Amount Achieved'
        },
        {
            accessor: 'AchievedPrcnt',
            Header: 'Achieved %'
        }
    ];

    const data = [
        {
            Month: '01',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt: 20
        },
        {
            Month: '02',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '03',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '04',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '05',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '06',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '07',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '08',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '09',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '10',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '11',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
        {
            Month: '12',
            SalesTarget: 100,
            AmountAchieved: 18,
            AchievedPrcnt:20
        },
    ];

    const SalesTargetTable = () => {

        return (
            <div className={'bg-white p-3 rounded-3 shadow-sm'}>
                <AdvanceTableWrapper
                    columns={columns}
                    data={data}
                    sortable
                >
                    <Row className="flex-start-center mb-3">
                        <Col xs="auto" sm={6} lg={5}>
                            <AdvanceTableSearchBox table/>
                        </Col>
                    </Row>
                    <AdvanceTable
                        table
                        headerClassName="bg-300 text-900 text-nowrap align-middle"
                        rowClassName="align-middle "
                        tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </AdvanceTableWrapper>
            </div>

        );
    }



export default SalesTargetTable
