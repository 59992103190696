import React from 'react';
import PropTypes from 'prop-types';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import {Card, Col, Row} from 'react-bootstrap';
import CustomerGradingSummaryChart from './CustomerGradingSummaryChart';
import {leadsData} from 'data/dashboard/crm';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import {GRADING_SUMMARY_DATA} from "../data";
import {useSafeQuery} from "@dladio/hooks";
import {useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {QUERIES} from "../../../../consts";

const GradingItem = ({item, isLast}) => {
    return (
        <Flex
            justifyContent="between"
            alignItems="center"
            className={classNames('border-bottom py-3', {
                'border-bottom-0 pb-0': isLast
            })}>
            <Flex>
                <h6 className="text-700 mb-0">{item.name}</h6>
            </Flex>
            <h6 className="text-700 mb-0">{item.value}</h6>
        </Flex>
    );
};

const CustomerGradingSummary = () => {
    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: QUERIES.GET_CUSTOMER_GRADING_SUMMARY,
            user: 16,
            year: 2022,
            month: 9
        },
    });

    let resolvingValue = _.get(data, ["message"]) ?? []

    return (
        <Card className="h-100">
            <FalconCardHeader
                title="Customer Grading Summary"
                titleTag="h6"
                className="border-200 border-bottom py-2"
                endEl={<CardDropdown/>}
            />
            <Card.Body as={Row}>
                <Col md={5} xxl={12} className="mb-xxl-1">
                    <CustomerGradingSummaryChart data={resolvingValue}/>
                </Col>
                <Col xxl={12} md={7}>
                    <hr className="mx-ncard mb-0 d-md-none d-xxl-block"/>

                    {!_.isEmpty(resolvingValue) ? (
                        resolvingValue.map((line, index) => (
                            <GradingItem
                                key={index}
                                item={line}
                                isLast={index === resolvingValue.length - 1}
                            />
                        ))
                    ) : null}
                </Col>
            </Card.Body>
        </Card>
    );
};

GradingItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        title: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        img: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired
    }),
    isLast: PropTypes.bool.isRequired
};

export default CustomerGradingSummary;
