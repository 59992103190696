import React from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import BpGroupWiseProfitabilityChart from './BpGroupWiseProfitabilityChart';
import Flex from 'components/common/Flex';
import CardDropdown from 'components/common/CardDropdown';

const BpGroupWiseProfitability = () => {
  return (
    <Card>
      <FalconCardHeader
        title="Business Partner Group Wise Profitability"
        titleTag="h6"
        className="border-200 border-bottom py-2"
        endEl={<CardDropdown />}
      />
      <Card.Body dir="ltr">
        <Flex
          justifyContent="between"
          alignItems="center"
          className="rtl-row-reverse"
        >
          <h6 className="fs--2 text-500 flex-1">Channel</h6>
          <h6 className="fs--2 text-500 mx-2">Achievement</h6>
          <h6 className="fs--2 text-500">Conversion</h6>
        </Flex>
        <BpGroupWiseProfitabilityChart />
      </Card.Body>
    </Card>
  );
};

export default BpGroupWiseProfitability;
