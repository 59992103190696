import { Card } from "react-bootstrap";

const StatsCard = ({ title, value, varient = 'primary' }) => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Card className="h-100 py-2" bg={varient} text={'white'}>
            <Card.Body>
                <Card.Title as="div" style={{ 'textAlign': 'center' }}>{formatter.format(Number(value))}</Card.Title>
                <Card.Text style={{ 'textAlign': 'center' }}>
                    {title}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default StatsCard