import { useAppBridgeSelector } from "@dladio/app-bridge"
import { useLocalStore, useSafeQuery } from "@dladio/hooks"
import { useExecMethodApiQuery } from "@dladio/service"
import { EXEC_QUERY_METHOD } from "@dladio/service/dist/const/erp-method.consts"
import { STORE } from "app/consts"
import FalconCardHeader from "components/common/FalconCardHeader"
import _ from "lodash"
import { Card, Col, Row } from "react-bootstrap"
import WidgetSectionTitle from "widgets/WidgetSectionTitle"
import OverallSalesByNewAndExisting from "./components/OverallSalesByNewAndExisting/OverallSalesByNewAndExisting"
import OverallSalesDistribution from "./components/OverallSalesDistribution/OverallSalesDistribution"
import OverallSalesDistributionYearly from "./components/OverallSalesDistributionYearly/OverallSalesDistributionYearly"
import QuarterlySalesGuage from "./components/QuarterlySalesGuage/QuarterlySalesGuage"
import QuarterlySalesGuageForSummary from "./components/QuarterlySalesGuageForSummary/QuarterlySalesGuageForSummary"
import StatsCard from "./components/StatsCard"
import YearlySalesGuage from "./components/YearlySalesGuage/YearlySalesGuage"

const AnnualSummary = () => {

    const { model } = useAppBridgeSelector()
    const { model: localModel } = useLocalStore(STORE.SALES_DASH)

    const year = _.get(localModel, ['selectedYearMonth', 'year'])
    const quarter = _.get(localModel, ['selectedYearMonth', 'quarter'])
    const user = _.get(model, ['ui', 'currentUser', 'sap_id'])

    const { data } = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "62a19769cf",
            quarter: quarter,
            year: year,
            user: user
        },
    });

    const chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    const newTraget = _.get(chartData, "newTraget") ?? 0;
    const oldTraget = _.get(chartData, "oldTraget") ?? 0;
    const newAchievement = _.get(chartData, "newAchievement") ?? 0;
    const oldAchievement = _.get(chartData, "oldAchievement") ?? 0;

    const getQuarterName = () => {

        let value = ''

        switch (quarter) {
            case 1:
                value = '3rd'
                break;
            case 2:
                value = '4th'
                break;
            case 3:
                value = '1st'
                break;
            case 4:
                value = '2nd'
                break;
        }

        return value
    }

    return (
        <Row className={"g-3 mb-3"}>
            <Col lg={12}>
                <WidgetSectionTitle
                    icon="list"
                    title={"Annual Summary"}
                    r
                    subtitle={"Financial Year - " + year + "/" + (year + 1)}
                    transform="shrink-2"
                    className="mb-2"
                />
            </Col>

            <Col lg={6}>
                <StatsCard varient="danger" title={"Total Target"}
                    value={(newTraget + oldTraget)} />
            </Col>

            <Col lg={6}>
                <StatsCard varient="secondary" title={"Total Achievement"}
                    value={newAchievement + oldAchievement} />
            </Col>

            <Col lg={6}>
                <YearlySalesGuage data={chartData}></YearlySalesGuage>
            </Col>

            <Col lg={6}>
                <OverallSalesDistributionYearly />
            </Col>

            <YearWideSummary />
        </Row>
    )
}

// 00762099e3

const YearWideSummary = () => {

    const { model } = useAppBridgeSelector()
    const { model: localModel } = useLocalStore(STORE.SALES_DASH)

    const year = _.get(localModel, ['selectedYearMonth', 'year'])
    const quarter = _.get(localModel, ['selectedYearMonth', 'quarter'])
    const user = _.get(model, ['ui', 'currentUser', 'sap_id'])

    const getFinantialYearName = () => {
        return year + "/" + (year + 1)
    }

    return (
        <Row className={"g-3 mb-3"}>
            <Col lg={12}>
                <WidgetSectionTitle
                    icon="list"
                    title={"Quarter Performance Summary for Financial Year"}
                    r
                    subtitle={getFinantialYearName()}
                    transform="shrink-2"
                    className="mb-2 mt-4"
                />
            </Col>


            <Col lg={6}>
                <QuarterSummary quarter={3} year={2023} />
            </Col>

            <Col lg={6}>
                <QuarterSummary quarter={4} year={2023} />
            </Col>

            <Col lg={6}>
                <QuarterSummary quarter={1} year={2024} />
            </Col>

            <Col lg={6}>
                <QuarterSummary quarter={2} year={2024} />
            </Col>

        </Row>
    )
}

const QuarterSummary = ({ quarter, year }) => {

    const { model } = useAppBridgeSelector()
    const { model: localModel } = useLocalStore(STORE.SALES_DASH)

    const user = _.get(model, ['ui', 'currentUser', 'sap_id'])

    const { data } = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "00762099e3",
            quarter: quarter,
            year: year,
            user: user
        },
    });

    const chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    const newTraget = _.get(chartData, "newTraget") ?? 0;
    const oldTraget = _.get(chartData, "oldTraget") ?? 0;
    const newAchievement = _.get(chartData, "newAchievement") ?? 0;
    const oldAchievement = _.get(chartData, "oldAchievement") ?? 0;

    const getFinantialYearName = () => {
        return year + "/" + (year + 1)
    }

    const getQuarterName = () => {
        let value = ''

        switch (quarter) {
            case 1:
                value = '3rd'
                break;
            case 2:
                value = '4th'
                break;
            case 3:
                value = '1st'
                break;
            case 4:
                value = '2nd'
                break;
        }

        return value
    }

    return (
        <Card className="h-100 py-2">
            <FalconCardHeader
                title={getQuarterName() + " Quarter Performance"}
                titleTag="h6"
                className="border-200 border-bottom py-2"
            />
            <Card.Body>
                <QuarterlySalesGuageForSummary data={chartData} />
            </Card.Body>
        </Card>
    )
}

export default AnnualSummary