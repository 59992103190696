import React from 'react';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import {useSafeQuery} from "@dladio/hooks";
import {dispatchAction} from "@dladio/app-bridge"
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {useExecMethodApiQuery} from "@dladio/service";
import {useDispatch} from "react-redux";


const StatCard = ({stat, query, prefix, title, link, ...rest}) => {

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: query,
        },
    });

    let resolvingData = _.get(data, "message.value") ?? 0

    const onClickHandler = () => {
        if (link) {
            console.log("link", link)
            dispatchAction({
                action: 'navigate',
                payload: `/app/page/${link}`
            })
        }
    }

    let badgeText = "";
    return (
        <Card className={classNames(`overflow-hidden m-0 ${link ? 'cursor-pointer' : ''}`)} {...rest}
              onClick={onClickHandler}>
            {/*<Background image={image} className="bg-card" />*/}
            <Card.Body className="position-relative">
                <h6>
                    {title}
                    {badgeText && (
                        <SoftBadge bg={"blue"} pill className="ms-2">
                            {badgeText}
                        </SoftBadge>
                    )}
                </h6>
                <div
                    className={classNames(
                        'display-4 fs-4 fw-normal font-sans-serif'
                    )}
                >
                    <CountUp
                        start={0}
                        end={resolvingData}
                        duration={2.75}
                        suffix={""}
                        prefix={prefix}
                        separator=","
                        decimals={2 ? 2 : 0}
                        decimal="."
                    />
                </div>
                <Link to={""} className="fw-semi-bold fs--1 text-nowrap">
                    {/*{linkText}*/}
                </Link>
            </Card.Body>
        </Card>
    );
};

StatCard.propTypes = {
    stat: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
        decimal: PropTypes.bool,
        suffix: PropTypes.string,
        prefix: PropTypes.string,
        valueClassName: PropTypes.string,
        linkText: PropTypes.string,
        link: PropTypes.string,
        badgeText: PropTypes.string,
        badgeBg: PropTypes.string,
        image: PropTypes.string,
        className: PropTypes.string
    })
};

export default StatCard;
