import { useLocalStore } from "@dladio/hooks";
import { STORE } from "app/consts";
import { tooltipFormatter } from "helpers/echart-utils";
import { getColor } from "helpers/utils";
import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import WidgetSectionTitle from "widgets/WidgetSectionTitle";
import BasicBarChart from "./bar-chart/barChart";

const BpCategoryWiseSalesStatsForYear = () => {

    const year = _.get(localModel, 'selectedYearMonth.year') || 2022

    const body = {
        "name": "ba73806f8a",
        "quarter": _.get(localModel, 'selectedYearMonth.quarter') || 4,
        "year": year
    }

    const { model: localModel } = useLocalStore(STORE.SALES_DASH)

    const data = (data) => {

        if (_.isArray(data)) {
            let xAxis = {
                type: 'category',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: getColor('gray-300'),
                        type: 'solid'
                    }
                },
                axisLabel: {
                    color: getColor('gray-500')
                },
                axisTick: { show: false },
                splitLine: { show: false },
                data: data.map((item) => (item?.Segment))
            }

            let series = [
                {
                    name: 'Target',
                    type: 'bar',
                    data: data.map((item) => (item?.Traget)),
                    itemStyle: {
                        borderRadius: [0, 3, 3, 0],
                        width: '10px'
                    }
                },
                {
                    name: 'Achievement',
                    type: 'bar',
                    data: data.map((item) => (item?.Achievement)),
                }
            ]

            return (
                {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        padding: [7, 10],
                        backgroundColor: getColor('gray-100'),
                        borderColor: getColor('gray-300'),
                        textStyle: { color: getColor('dark') },
                        borderWidth: 1,
                        transitionDuration: 0,
                        formatter: tooltipFormatter
                    },
                    xAxis: xAxis,
                    yAxis: {},
                    series: series
                }
            )

        } else {
            return {}
        }

    }

    return (
        <Row className={"g-3 mb-3"}>
            <Col lg={12}>
                <WidgetSectionTitle
                    icon="list"
                    title={"BP Segment Summary"}
                    r
                    subtitle={"Financial Year - " + year + "/" + (year + 1)}
                    transform="shrink-2"
                    className="mb-2"
                />
            </Col>
            <Col lg={12}>
                <BasicBarChart title={'BP Segment wise Sales & Targets'}
                    body={body}
                    data={data}
                />
            </Col>
        </Row>
    )
}

export default BpCategoryWiseSalesStatsForYear