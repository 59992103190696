import { useLocalStore } from "@dladio/hooks";
import { STORE } from "app/consts";
import Flex from "components/common/Flex";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
// import barChart from "../../../../assets/img/illustrations/crm-bar-chart.png";
import barChart from "../../../assets/img/illustrations/crm-bar-chart.png"
import lineChart from "../../../assets/img/illustrations/crm-line-chart.png";

const ParamSelector = () => {
    const [year, setYear] = useState(new Date())
    const [quarter, setQuarter] = useState(moment().quarter())

    const { setStore } = useLocalStore(STORE.SALES_DASH)

    useEffect(() => {
        setStore(STORE.SELECTED_YEAR_MONTH, {
            year: moment(year).year(),
            quarter: +quarter
        })
    }, [year, quarter])

    return (
        <Card className="bg-100 shadow-none border mb-3">
            <Card.Body className="py-0">
                <Row className="g-0 justify-content-between">
                    <Col sm="auto">
                        <Flex alignItems="center">
                            <img src={barChart} width={90} alt="..." className="ms-n4" />
                            <div>
                                <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                                <h4 className="text-primary fw-bold mb-0">
                                    Tea Drop
                                    <span className="text-info fw-medium"> Sales Bird Eye</span>
                                </h4>
                            </div>
                            <img
                                src={lineChart}
                                width={140}
                                alt="..."
                                className="ms-n4 d-md-none d-lg-block"
                            />
                        </Flex>
                    </Col>
                    <Col md="auto" className="mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                        
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ParamSelector;
