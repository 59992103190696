import {useAppBridgeSelector} from "@dladio/app-bridge";
import {useLocalStore, useSafeQuery} from "@dladio/hooks";
import {useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import FalconCardHeader from "components/common/FalconCardHeader";
import _ from 'lodash';
import React from 'react';
import {Card, Col, Row, Table} from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import {STORE} from "../../../../consts";
import OverallSales from "../OverallSales/OverallSales";
import OverallSalesByNewAndExisting from "../OverallSalesByNewAndExisting/OverallSalesByNewAndExisting";
import OverallSalesByNewAndExistingMonthly
    from "../OverallSalesByNewAndExistingMonthly/OverallSalesByNewAndExistingMonthly";
import OverallSalesDistribution from "../OverallSalesDistribution/OverallSalesDistribution";
import OverallSalesDistributionYearly from "../OverallSalesDistributionYearly/OverallSalesDistributionYearly";
import QuarterlySalesGuage from '../QuarterlySalesGuage/QuarterlySalesGuage';
import QuarterlySalesGuageForSummary from "../QuarterlySalesGuageForSummary/QuarterlySalesGuageForSummary";
import YearlySalesGuage from "../YearlySalesGuage/YearlySalesGuage";

export const StatsItem = ({title, value, varient = 'primary'}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Card className="h-100 py-2" bg={varient} text={'white'}>
            <Card.Body>
                <Card.Title as="div" style={{'textAlign': 'center'}}>{formatter.format(Number(value))}</Card.Title>
                <Card.Text style={{'textAlign': 'center'}}>
                    {title}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

const SalesEmployeeSalesSummary = () => {

    const {model} = useAppBridgeSelector()
    const {model: localModel} = useLocalStore(STORE.SALES_DASH)

    const year = _.get(localModel, ['selectedYearMonth', 'year'])
    const quarter = _.get(localModel, ['selectedYearMonth', 'quarter'])
    const user = _.get(localModel, 'user')

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "75dcf219e7",
            quarter: quarter,
            year: year,
            user: user
        },
    });

    const chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    const newTraget = _.get(chartData, "newTraget") ?? 0;
    const oldTraget = _.get(chartData, "oldTraget") ?? 0;
    const newAchievement = _.get(chartData, "newAchievement") ?? 0;
    const oldAchievement = _.get(chartData, "oldAchievement") ?? 0;

    const getQuarterName = () => {
        let q = _.get(localModel, ['selectedYearMonth', 'quarter'])
        let value = ''

        switch (q) {
            case 1:
                value = '3rd'
                break;
            case 2:
                value = '4th'
                break;
            case 3:
                value = '1st'
                break;
            case 4:
                value = '2nd'
                break;
        }

        return value
    }

    return (
        <>
            <Row className={"g-3 mb-3"}>
                <Col lg={12}>
                    <WidgetSectionTitle
                        icon="list"
                        title={"Current Quarter Performance"}
                        r
                        subtitle={getQuarterName() + " Quarter - " + +year + "/" + (year + 1)}
                        transform="shrink-2"
                        className="mb-2 mt-2"
                    />
                </Col>

                <Col lg={6}>
                    <StatsItem varient="danger" title={getQuarterName() + " Quarter Target"}
                               value={newTraget + oldTraget}/>
                </Col>

                <Col lg={6}>
                    <StatsItem varient="secondary" title={getQuarterName() + " Quarter Achievement"}
                               value={newAchievement + oldAchievement}/>
                </Col>

                <Col lg={6}>
                    <QuarterlySalesGuage data={chartData}></QuarterlySalesGuage>
                </Col>

                <Col lg={6}>
                    <OverallSalesDistribution/>
                </Col>

                <Col lg={12}>
                    <OverallSalesByNewAndExisting/>
                </Col>

                {/* <Col lg={4}>
                    <OverallSalesByNewAndExistingMonthly title={'New & Existing Business Performance Octomber'} />
                </Col>

                <Col lg={4}>
                    <OverallSalesByNewAndExistingMonthly title={'New & Existing Business Performance November'} />
                </Col>

                <Col lg={4}>
                    <OverallSalesByNewAndExistingMonthly title={'New & Existing Business Performance December'} />
                </Col> */}
            </Row>

            <YearlyStatus></YearlyStatus>

            <Row className={"g-3"}>
                <Col lg={12}>
                    <WidgetSectionTitle
                        icon="list"
                        title={"Items Sales Targets"}
                        r
                        subtitle="Item Targets for Christmas"
                        transform="shrink-2"
                        className="mb-2 mt-4"
                    />
                </Col>

                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <WholesaleItemTargets></WholesaleItemTargets>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <YearWideSummary/>
        </>
    );
};

const WholesaleItemTargets = () => {

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "557ca42786"
        },
    });

    const rows = !_.isEmpty(data) ? _.get(data, 'message') : [];

    return (
        <Table size='sm'>
            <thead>
            <tr>
                <th>Item Code</th>
                <th>Item Name</th>
                <th style={{"textAlign": "right"}}>Target Quantity</th>
                <th style={{"textAlign": "right"}}>Sold Quantity</th>
                <th style={{"textAlign": "right"}}>Balance Quantity</th>
            </tr>
            </thead>
            <tbody>
            {rows.map((item, index) => (
                <tr>
                    <td>{item.ItemCode}</td>
                    <td>{item.ItemName}</td>
                    <td style={{"textAlign": "right"}}>{item.Target}</td>
                    <td style={{"textAlign": "right"}}>{item.Achievement}</td>
                    <td style={{"textAlign": "right"}}>{item.Target - item.Achievement}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}

const YearlyStatus = () => {

    const {model} = useAppBridgeSelector()
    const {model: localModel} = useLocalStore(STORE.SALES_DASH)

    const year = _.get(localModel, ['selectedYearMonth', 'year'])
    const quarter = _.get(localModel, ['selectedYearMonth', 'quarter'])
    const user = _.get(localModel, 'user')

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "3c21e9d644",
            quarter: quarter,
            year: year,
            user: user
        },
    });

    const chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    const newTraget = _.get(chartData, "newTraget") ?? 0;
    const oldTraget = _.get(chartData, "oldTraget") ?? 0;
    const newAchievement = _.get(chartData, "newAchievement") ?? 0;
    const oldAchievement = _.get(chartData, "oldAchievement") ?? 0;

    return (
        <Row className={"g-3 mb-3"}>
            <Col lg={12}>
                <WidgetSectionTitle
                    icon="list"
                    title={"Annual Performance"}
                    r
                    subtitle={"Financial Year - " + year + "/" + (year + 1)}
                    transform="shrink-2"
                    className="mb-2 mt-4"
                />
            </Col>

            <Col lg={6}>
                <StatsItem varient="danger" title={"Total Target"} value={newTraget + oldTraget}/>
            </Col>

            <Col lg={6}>
                <StatsItem varient="secondary" title={"Total Achievement"} value={newAchievement + oldAchievement}/>
            </Col>

            {/* <Col lg={3}>
                <StatsItem varient="warning" title={"Sales from Existing BPs"} value={oldAchievement} />
            </Col>
            <Col lg={3}>
                <StatsItem varient="info" title={"Sales from New BPs"} value={newAchievement} />
            </Col> */}

            <Col lg={6}>
                <YearlySalesGuage data={chartData}></YearlySalesGuage>
            </Col>

            <Col lg={6}>
                <OverallSalesDistributionYearly/>
            </Col>

        </Row>
    )
}

const YearWideSummary = () => {

    const {model} = useAppBridgeSelector()
    const {model: localModel} = useLocalStore(STORE.SALES_DASH)

    const year = _.get(localModel, ['selectedYearMonth', 'year'])
    const quarter = _.get(localModel, ['selectedYearMonth', 'quarter'])
    const user = _.get(localModel, 'user')

    const getFinantialYearName = () => {
        return year + "/" + (year + 1)
    }

    return (
        <Row className={"g-3 mb-3"}>
            <Col lg={12}>
                <WidgetSectionTitle
                    icon="list"
                    title={"Quarter Performance Summary for Financial Year"}
                    r
                    subtitle={getFinantialYearName()}
                    transform="shrink-2"
                    className="mb-2 mt-4"
                />
            </Col>


            <Col lg={6}>
                <QuarterSummary quarter={3} year={2022}/>
            </Col>

            <Col lg={6}>
                <QuarterSummary quarter={4} year={2022}/>
            </Col>

            <Col lg={6}>
                <QuarterSummary quarter={1} year={2023}/>
            </Col>

            <Col lg={6}>
                <QuarterSummary quarter={2} year={2023}/>
            </Col>

        </Row>
    )
}

const QuarterSummary = ({quarter, year}) => {

    const {model} = useAppBridgeSelector()
    const {model: localModel} = useLocalStore(STORE.SALES_DASH)

    const user = _.get(localModel, 'user')

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "75dcf219e7",
            quarter: quarter,
            year: year,
            user: user
        },
    });

    const chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    const newTraget = _.get(chartData, "newTraget") ?? 0;
    const oldTraget = _.get(chartData, "oldTraget") ?? 0;
    const newAchievement = _.get(chartData, "newAchievement") ?? 0;
    const oldAchievement = _.get(chartData, "oldAchievement") ?? 0;

    const newGrossProfit = _.get(chartData, "newGrossProfit") ?? 0;
    const oldGrossProfit = _.get(chartData, "oldGrossProfit") ?? 0;
    const newInvCount = _.get(chartData, "newInvCount") ?? 0;
    const oldInvCount = _.get(chartData, "oldInvCount") ?? 0;

    const newFreight = _.get(chartData, "newFreight") ?? 0;
    const oldFreight = _.get(chartData, "oldFreight") ?? 0;

    const newCogs = _.get(chartData, "newCogs") ?? 0;
    const oldCogs = _.get(chartData, "oldCogs") ?? 0;

    const getFinantialYearName = () => {
        return year + "/" + (year + 1)
    }

    const getQuarterName = () => {
        let value = ''

        switch (quarter) {
            case 1:
                value = '3rd'
                break;
            case 2:
                value = '4th'
                break;
            case 3:
                value = '1st'
                break;
            case 4:
                value = '2nd'
                break;
        }

        return value
    }

    return (
        <Card className="h-100 py-2">
            <FalconCardHeader
                title={getQuarterName() + " Quarter Performance"}
                titleTag="h6"
                className="border-200 border-bottom py-2"
            />
            <Col lg={12}>
                <Table size='sm'>
                    <thead>
                    <tr>
                        <th></th>
                        <th style={{"textAlign": "right"}}># of Inv.</th>
                        <th style={{"textAlign": "right"}}>Freight($)</th>
                        <th style={{"textAlign": "right"}}>GP (%)</th>
                        <th style={{"textAlign": "right"}}>COGS (%)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Existing</td>
                        <td style={{"textAlign": "right"}}>{oldInvCount}</td>
                        <td style={{"textAlign": "right"}}>{oldFreight}</td>
                        <td style={{"textAlign": "right"}}>{oldGrossProfit}</td>
                        <td style={{"textAlign": "right"}}>{oldCogs}</td>
                    </tr>
                    <tr>
                        <td>New</td>
                        <td style={{"textAlign": "right"}}>{newInvCount}</td>
                        <td style={{"textAlign": "right"}}>{newFreight}</td>
                        <td style={{"textAlign": "right"}}>{newGrossProfit}</td>
                        <td style={{"textAlign": "right"}}>{newCogs}</td>
                    </tr>
                    </tbody>
                </Table>
            </Col>
            <Card.Body>
                <QuarterlySalesGuageForSummary data={chartData}/>
            </Card.Body>
        </Card>
    )
}

export default SalesEmployeeSalesSummary;
