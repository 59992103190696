import { useLocalStore } from "@dladio/hooks";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import barChart from "../../../../../assets/img/illustrations/crm-bar-chart.png";
import lineChart from "../../../../../assets/img/illustrations/crm-line-chart.png";
import Flex from "../../../../../components/common/Flex";
import { STORE } from "../../../../consts";

const ParamSelector = () => {
    const [year, setYear] = useState(new Date())
    const [quarter, setQuarter] = useState(moment().quarter())

    const { setStore } = useLocalStore(STORE.SALES_DASH)

    useEffect(() => {
        setStore(STORE.SELECTED_YEAR_MONTH, {
            year: moment(year).year(),
            quarter: +quarter
        })
    }, [year, quarter])

    return (
        <Card className="bg-100 shadow-none border mb-3">
            <Card.Body className="py-0">
                <Row className="g-0 justify-content-between">
                    <Col sm="auto">
                        <Flex alignItems="center">
                            <img src={barChart} width={90} alt="..." className="ms-n4" />
                            <div>
                                <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                                <h4 className="text-primary fw-bold mb-0">
                                    Tea Drop
                                    <span className="text-info fw-medium"> Sales Hubble</span>
                                </h4>
                            </div>
                            <img
                                src={lineChart}
                                width={140}
                                alt="..."
                                className="ms-n4 d-md-none d-lg-block"
                            />
                        </Flex>
                    </Col>
                    <Col md="auto" className="mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                        {/*<Row className="crm__yearPicker g-3 me-2 gy-md-0 h-100 align-items-center">*/}
                        {/*    <Form.Select defaultValue={quarter} onChange={(e) => setQuarter(e.target.value)} size="sm"*/}
                        {/*        className="me-2">*/}
                        {/*        <option>Select Quarter</option>*/}
                        {/*        <option value={1}>1 Quarter</option>*/}
                        {/*        <option value={2}>2 Quarter</option>*/}
                        {/*        <option value={3}>3 Quarter</option>*/}
                        {/*        <option value={4}>4 Quarter</option>*/}
                        {/*    </Form.Select>*/}
                        {/*</Row>*/}

                        {/* <Row className="crm__yearPicker g-3 gy-md-0 h-100 align-items-center">
                            <DatePicker
                                selected={year}
                                onChange={(_year) => setYear(_year)}
                                className='form-control text-center'
                                dateFormat="yyyy"
                                showYearPicker />
                        </Row> */}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ParamSelector;
