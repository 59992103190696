import {Col, Row} from "react-bootstrap";
import GreetingCard from "./components/greetings/GreetingCard";
import LeadConversation from "./components/lead-conversation/LeadConversation";
import Kanban from "./components/kanban/Kanban";

const LeadDashboard = () => {
    return (
        <>
            <Row>
                <Col className={'pe-0'}>
                    <GreetingCard/>
                </Col>
                <Col>
                    <LeadConversation/>
                </Col>
            </Row>
            <Row className={'mt-2'}>
                <Col p={0}>
                    <Kanban/>
                </Col>
            </Row>
        </>
    );

}

export default LeadDashboard