import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Form, Row} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import SalesComparisonChart from './SalesComparisonChart';
import {useSafeQuery} from "@dladio/hooks";
import {useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import _ from "lodash";
import {QUERIES} from "../../../../consts";

const SalesComparison = () => {
    const chartRef = useRef(null);
    // const [salesInfo, setSalesInfo] = useState({})
    const salesInfo = useRef(null)

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: QUERIES.GET_SALES_COMPARISON,
            user: 16
        },
    });

    let resolvingData = _.get(data, ["message"]) ?? []

    const chartDataGenerator = () => {
        let prevYear = [];
        let currYear = [];
        let labels = [];

        if (!_.isEmpty(resolvingData)) {
            const [{dataKey: prevKey}, {dataKey: currKey}] = resolvingData["legend"]

            _.get(resolvingData, "data").forEach((line) => {
                prevYear.push(_.get(line, prevKey))
                currYear.push(_.get(line, currKey))
                labels.push(_.get(line, "name"))
            })
        }

        return {prevYear, currYear, labels}
    }

    const handleLegendToggle = name => {
        chartRef.current.getEchartsInstance().dispatchAction({
            type: 'legendToggleSelect', name
        });
    };

    return (
        <>
            <Card style={{height: '100%'}}>
                <Card.Header>
                    <Row className="flex-between-center g-0">
                        <Col xs="auto">
                            <h6 className="mb-0">Sales Comparison</h6>
                        </Col>
                        <Col xs="auto" className="d-flex">
                            <Form.Check
                                type="checkbox"
                                id="ecommerceLastMonth"
                                className="mb-0 d-flex"
                            >
                                <Form.Check.Input
                                    type="checkbox"
                                    className="form-check-input-primary"
                                    onClick={() => handleLegendToggle('lastMonth')}
                                    defaultChecked
                                />
                                <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                                    {!_.isEmpty(resolvingData["legend"]) ? resolvingData["legend"][0]["dataKey"] : "Previous Year"}
                                    <span className="text-dark d-none d-md-inline">
                            </span>
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check
                                type="checkbox"
                                id="ecommercePrevYear"
                                className="mb-0 d-flex ps-0 ps-md-3">
                                <Form.Check.Input
                                    type="checkbox"
                                    className="ms-2 form-check-input-warning opacity-75"
                                    onClick={() => handleLegendToggle('previousYear')}
                                    defaultChecked
                                />
                                <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                                    {!_.isEmpty(resolvingData["legend"]) ? resolvingData["legend"][1]["dataKey"] : "Current Year"}
                                    <span className="text-dark d-none d-md-inline">
                            </span>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                        <Col xs="auto">
                            <CardDropdown/>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="pe-xxl-0">
                    <SalesComparisonChart
                        {...chartDataGenerator()}
                        ref={chartRef}/>
                </Card.Body>
            </Card>
        </>
    );
};

SalesComparison.propTypes = {
    data: PropTypes.shape({
        lastMonth: PropTypes.array, previousYear: PropTypes.array
    })
};

export default SalesComparison;
