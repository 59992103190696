import React from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import {Card, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LeadConversationChart from './LeadConversationChart';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import {useDerive, useExecMethodApiQuery} from "@dladio/service";
import {useSafeQuery} from "@dladio/hooks";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {QUERIES} from "../../../../consts";

const LeadConversation = () => {

    const {data} = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD,
            body: {name: QUERIES?.GET_LEAD_CONVERSATION},
        },
        path: 'message'
    });

    return (
        <Card>
            <FalconCardHeader
                title="Lead Conversion"
                titleTag="h6"
                className="py-2"
            />
            <Card.Body className="pt-1">
                <LeadConversationChart data={data?.message ? data.message : []}/>
            </Card.Body>
        </Card>
    );
};

export default LeadConversation;
