import {dispatchAction} from "@dladio/app-bridge";

export const onClickBp = (row,lines)=>{
    dispatchAction({
        action: 'navigate',
        payload: `/app/page/cea858702d?id=${_.get(row,'card_code')}`
    })
}

export const onClickInvoiceNo = (row,lines)=>{
    dispatchAction({
        action: 'navigate',
        payload: `/app/page/b9db3c1932?id=${_.get(row,'invo_doc_entry')}`
    })
}
