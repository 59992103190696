import Flex from "../../../../../components/common/Flex";
import moment from "moment/moment";
import { STORE } from "../../../../consts";
import barChart from "../../../../../assets/img/illustrations/crm-bar-chart.png";
import lineChart from "../../../../../assets/img/illustrations/crm-line-chart.png";
import DatePicker from "react-datepicker";
import { useLocalStore } from "@dladio/hooks";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Form } from "react-bootstrap";
import { getAusQuarter } from "../../../../utils/current-date";

const ParamSelector = () => {
    const [date, setDate] = useState(moment(new Date()).format("YYYY-DD-MM"))

    const { setStore } = useLocalStore(STORE.SALES_DASH)

    useEffect(() => {
        setStore(STORE.SELECTED_YEAR_MONTH, {
            date: date
        })
    }, [date])

    return (
        <Card className="bg-100 shadow-none border mb-3">
            <Card.Body className="py-0">
                <Row className="g-0 justify-content-between">
                    <Col sm="auto">
                        <Flex alignItems="center">
                            <img src={barChart} width={90} alt="..." className="ms-n4" />
                            <div>
                                <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                                <h4 className="text-primary fw-bold mb-0">
                                    Tea Drop
                                    <span className="text-info fw-medium"> Activity Performace {date}</span>
                                </h4>
                            </div>
                            <img
                                src={lineChart}
                                width={140}
                                alt="..."
                                className="ms-n4 d-md-none d-lg-block"
                            />
                        </Flex>
                    </Col>
                    <Col md="auto" className="mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                        <Row className="crm__yearPicker g-3 gy-md-0 h-100 align-items-center">
                            <input type={'date'} onChange={(e) => setDate(e.target.value)} />
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ParamSelector;
