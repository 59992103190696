export const documentationRoutes = {
    label: 'Helios Dashboards',
    labelDisable: true,
    active: true,
    children: [
        // {
        //     name: 'Key Acc. Management',
        //     active: true,
        //     icon: 'chart-pie',
        //     to: '/dashboard/key-account-management',
        // },
        // {
        //     name: 'Lead Dashboard',
        //     active: false,
        //     icon: 'chart-pie',
        //     to: '/dashboard/lead-dashboard',
        // },
        {
            name: 'Sales Dashboard',
            active: false,
            icon: 'chart-pie',
            to: '/dashboard/sales-dashboard',
        },
        {
            name: 'Sales Dashboard',
            active: false,
            icon: 'chart-pie',
            to: '/dashboard/sales-dashboard-mgr-view',
        },
        {
            name: 'Sales Dashboard',
            active: false,
            icon: 'chart-pie',
            to: '/dashboard/acivitity-management',
        },
        {
            name: 'Sales Bird Eye',
            active: false,
            icon: 'chart-pie',
            to: '/dashboard/sales-bird-eye',
        },
        {
            name: 'Sales Strike',
            active: false,
            icon: 'chart-pie',
            to: '/dashboard/sales-strike',
        },

        // {
        //     name: 'Kanban',
        //     active: false,
        //     icon: 'chart-pie',
        //     to: '/app/kanban',
        // }
    ]
};

export default [
    documentationRoutes
];
