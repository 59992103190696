import React, {useEffect} from 'react';
import {Card, Row, Spinner, Tab} from 'react-bootstrap';
import SalesVsGrossProfitChart from './SalesVsGrossProfitChart';
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import CardDropdown from "../../../../../components/common/CardDropdown";
import {useLocalStore, useSafeQuery} from "@dladio/hooks";
import {useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import _ from "lodash";
import {QUERIES, STORE} from "../../../../consts";
import moment from "moment";
import {CURRENT_MONTH, CURRENT_YEAR} from "../../../../utils/current-date";

const SalesVsGrossProfit = () => {
    const {select} = useLocalStore(STORE.KEY_ACC_MGT)
    let date = select(STORE.SELECTED_YEAR_MONTH)
    let selectedYear = !_.isEmpty(date) ? date.year : CURRENT_YEAR;
    let selectedMonth = !_.isEmpty(date) ? date.month : CURRENT_MONTH
    let calculatedDate = `${selectedYear}-${selectedMonth}-01`

    const {data, currentData} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: QUERIES.GET_SALES_VS_GROSS_PROFIT,
            user: 16,
            date: calculatedDate
        }
    });

    let resolvingValue = _.get(data, ["message"]) ?? []

    const generateChartData = () => {
        let sales = [];
        let profits = [];
        let labels = [];

        if (!_.isEmpty(resolvingValue)) {
            resolvingValue.forEach(({Day, Revenue, GrossProfit}) => {
                sales.push(Revenue)
                profits.push(GrossProfit)
                labels.push(Day)
            })
        }

        return {sales, profits, labels}
    }

    return (
        <Card className="mt-3">
            <Tab.Container id="audience-tab" defaultActiveKey="users">
                <FalconCardHeader
                    title="Sales Vs Gross Profit (Current Month)"
                    titleTag="h6"
                    className="border-200 border-bottom d-flex py-2"
                    endEl={<CardDropdown/>}>
                    {
                        (!_.isEmpty(resolvingValue) && _.isEmpty(currentData)) ? <Refreshing /> : null
                    }
                </FalconCardHeader>

                <Card.Body>
                    <Row className="g-1">
                        {!_.isEmpty(resolvingValue) ? (
                            <SalesVsGrossProfitChart {...generateChartData()}/>
                        ) : (
                            <div className="w-100 d-flex justify-content-center align-items-center">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                    </Row>
                </Card.Body>
            </Tab.Container>
        </Card>
    );
};

const Refreshing = () => {

    return (
        <div className={"d-flex align-items-center"}>
            <Spinner style={{width: '18px', height: "18px"}} animation="border" role="status">
            </Spinner>
            <span className={"ms-1 animate"} style={{fontSize: "13px"}}>Loading...</span>
        </div>
    )
}

export default SalesVsGrossProfit;
