import { useAppBridgeSelector } from "@dladio/app-bridge";
import { useLocalStore, useSafeQuery } from "@dladio/hooks";
import { useExecMethodApiQuery } from "@dladio/service";
import { EXEC_QUERY_METHOD } from "@dladio/service/dist/const/erp-method.consts";
import { STORE } from "app/consts";
import FalconCardHeader from "components/common/FalconCardHeader"
import _ from "lodash";
import { Card } from "react-bootstrap"
import MonthlySalesGuage from "../MonthlySalesGuage/MonthlySalesGuage"

const MonthlyPerformance = ({ month, postfix }) => {

    const { model } = useAppBridgeSelector()
    const { model: localModel } = useLocalStore(STORE.SALES_DASH)

    const year = _.get(localModel, ['selectedYearMonth', 'year'])
    const quarter = _.get(localModel, ['selectedYearMonth', 'quarter'])
    const user = _.get(model, ['ui', 'currentUser', 'sap_id'])

    const { data } = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "e0cb67c9f3",
            quarter: quarter,
            year: year,
            month: month,
            user: user
        },
    });

    const chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    return (
        <Card>
            <FalconCardHeader
                title={`${month}${postfix} Month Performance`}
                titleTag="h6"
                className="border-200 border-bottom py-2"
            />
            <Card.Body>
                <MonthlySalesGuage data={chartData} />
            </Card.Body>
        </Card>
    )
}

export default MonthlyPerformance