import React from 'react';
import KanbanColumn from './KanbanColumn';

const KanbanContainer = () => {
    let kanbanColumn = ['0-New', '1-Contact Made', '2-Sampling', '3-Pricing', '4-Account Application Sent', '5-Account Application Received']

    return (
        //kanban-container me-n3 scrollbar
        <div className={'d-flex flex-row scrollbar'}>
            {
                kanbanColumn.map((item, idx) => (
                    <KanbanColumn
                        key={idx}
                        type={item}
                    />
                ))
            }
        </div>

    );
};

export default KanbanContainer;
