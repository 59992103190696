import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {KanbanContext} from 'context/Context';
import {useSafeQuery} from "@dladio/hooks";
import {useDerive, useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";

const KanbanColumnHeader = ({title, sapId}) => {

    const {data} = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD,
            body: {
                "name": "b132ac6f90",
                "status": title,
                "sap_id": sapId ? sapId : ''
            },
        },
        path: 'message'
    });

    return (
        <div className="kanban-column-header overflow-hidden">
            <h5 className="fs-0 mb-0">
                {title} <span className="text-500">({data?.message?.value})</span>
            </h5>
        </div>
    );
};

KanbanColumnHeader.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default KanbanColumnHeader;
