import { getColor } from "../../../../../helpers/utils";
import { tooltipFormatter } from "../../../../../helpers/echart-utils";
import { Card } from "react-bootstrap";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { useLocalStore, useSafeQuery } from "@dladio/hooks";
import { useExecMethodApiQuery } from "@dladio/service";
import { EXEC_QUERY_METHOD } from "@dladio/service/dist/const/erp-method.consts";
import _ from "lodash";
import FalconCardHeader from "../../../../../components/common/FalconCardHeader";
import { useAppBridgeSelector } from "@dladio/app-bridge";
import { STORE } from "../../../../consts";

const OverallSalesByNewAndExisting = () => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const { model } = useAppBridgeSelector()
    const { model: localModel } = useLocalStore(STORE.SALES_DASH)

    const year = _.get(localModel, ['selectedYearMonth', 'year'])
    const quarter = _.get(localModel, ['selectedYearMonth', 'quarter'])
    const user = _.get(model, ['ui', 'currentUser', 'sap_id'])

    const getQuarterName = () => {
        let q = _.get(localModel, ['selectedYearMonth', 'quarter'])
        let value = ''

        switch (q) {
            case 1:
                value = '3rd'
                break;
            case 2:
                value = '4th'
                break;
            case 3:
                value = '1st'
                break;
            case 4:
                value = '2nd'
                break;
        }

        return value
    }

    const { data } = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "75dcf219e7",
            quarter: quarter,
            year: year,
            user: user
        },
    });

    let chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    const getOption = () => ({
        color: [getColor('danger'), getColor('info')],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            textStyle: { color: getColor('dark') },
            borderWidth: 1,
            transitionDuration: 0,
            // formatter: tooltipFormatter
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: value => value / 1000 + 'k',
                color: getColor('gray-500')
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: getColor('gray-300'),
                    type: 'solid'
                }
            },
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                    color: getColor('gray-200')
                }
            }
        },
        xAxis: {
            type: 'category',
            axisLine: {
                show: true,
                lineStyle: {
                    color: getColor('gray-300'),
                    type: 'solid'
                }
            },
            axisLabel: {
                color: getColor('gray-500')
            },
            axisTick: { show: false },
            splitLine: { show: false },
            data: ['Existing Business', 'New Business']
        },
        series: [
            {
                name: 'Target',
                type: 'bar',
                data: [_.get(chartData, "oldTraget") || 0, _.get(chartData, "newTraget") || 0],
                itemStyle: {
                    borderRadius: [0, 3, 3, 0]
                }
            },
            {
                name: 'Achievement',
                type: 'bar',
                data: [_.get(chartData, "oldAchievement") || 0, _.get(chartData, "newAchievement") || 0],
                itemStyle: {
                    borderRadius: [0, 3, 3, 0]
                }
            }
        ],
        grid: { right: 15, left: '8%', bottom: '10%', top: 10 }
    });

    return (
        <Card>
            <FalconCardHeader
                title={"New & Existing Business Performance " + getQuarterName() + " Quarter - " + year + "/" + (year + 1)}
                titleTag="h6"
                className="border-200 border-bottom py-2"
            />
            <Card.Body>
                <ReactEChartsCore
                    echarts={echarts}
                    lazyUpdate={true}
                    option={getOption()}
                    style={{ minHeight: '18.75rem' }}
                />
            </Card.Body>
        </Card>
    );
}

export default OverallSalesByNewAndExisting