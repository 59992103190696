import {Card} from 'react-bootstrap'
import * as echarts from 'echarts/core';
import FalconCardHeader from "../../../../components/common/FalconCardHeader"
import ReactEChartsCore from "echarts-for-react/lib/core";
import {useEffect, useRef} from "react";
import {getColor, rgbaColor} from 'helpers/utils';

const ArPieChart = ({data}) => {

    const chartRef = useRef(null)

    const getOption = () => ({
        legend: {
            left: 'left',
            textStyle: {
                color: getColor('gray-600')
            }
        },
        series: [
            {
                type: 'pie',
                radius: window.innerWidth < 530 ? '45%' : '60%',
                label: {
                    color: getColor('gray-700')
                },
                center: ['50%', '55%'],
                data: [
                    {
                        value: data?.message?.wholesaleAmount || 0,
                        name: 'Wholesale',
                        itemStyle: {
                            color: getColor('primary')
                        }
                    },
                    {
                        value: data?.message?.intAmount || 0,
                        name: 'International',
                        itemStyle: {
                            color: getColor('danger')
                        }
                    },
                    {
                        value: data?.message?.bunningAmount || 0,
                        name: 'Bunnings',
                        itemStyle: {
                            color: getColor('success')
                        }
                    },
                    {
                        value: data?.message?.agentAmount || 0,
                        name: 'Distributors / Agents',
                        itemStyle: {
                            color: getColor('warning')
                        }
                    },
                    {
                        value: data?.message?.smmAmount || 0,
                        name: 'South Melbourne Market',
                        itemStyle: {
                            color: getColor('purple')
                        }
                    }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: rgbaColor(getColor('gray-600'), 0.5)
                    }
                }
            }
        ],
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            textStyle: {color: getColor('dark')},
            borderWidth: 1,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        }
    });

    const updateDimensions = () => {
        if (window.innerWidth < 530) {
            chartRef.current.getEchartsInstance().setOption({
                series: [{radius: '45%'}]
            });
        } else
            chartRef.current.getEchartsInstance().setOption({
                series: [{radius: '60%'}]
            });
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <Card className={""}>
            <FalconCardHeader
                title="Title"
                titleTag="h6"
                className="border-200 border-bottom py-2"
            />
            <Card.Body>
                <ReactEChartsCore
                    echarts={echarts}
                    option={getOption()}
                    ref={chartRef}
                    style={{height: '28rem'}}
                />
            </Card.Body>
        </Card>
    )
}

export default ArPieChart