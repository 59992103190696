import React from "react";
import { Row } from "react-bootstrap";
import ParamSelector from './components/ParamSelector/ParamSelector';
import SalesEmployeeSalesSummary from "./components/SalesEmployeeSalesSummary/SalesEmployeeSalesSummary";

const SalesDashboard = () => {
    return (
        <>
            <Row className={"g-3"}>
                <ParamSelector />
            </Row>
            <Row>
                <SalesEmployeeSalesSummary />
            </Row>
        </>
    );

}

export default SalesDashboard