import Flex from "../../../../../components/common/Flex";
import moment from "moment/moment";
import { STORE } from "../../../../consts";
import barChart from "../../../../../assets/img/illustrations/crm-bar-chart.png";
import lineChart from "../../../../../assets/img/illustrations/crm-line-chart.png";
import DatePicker from "react-datepicker";
import { useLocalStore } from "@dladio/hooks";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Form } from "react-bootstrap";
import { getAusQuarter } from "../../../../utils/current-date";

const ParamSelector = () => {
    const [year, setYear] = useState(new Date())
    const [quarter, setQuarter] = useState(moment().quarter())
    const [user, setUser] = useState()

    const { setStore } = useLocalStore(STORE.SALES_DASH)

    useEffect(() => {
        setStore(STORE.SELECTED_YEAR_MONTH, {
            year: moment(year).year(),
            quarter: +quarter,
        })

        setStore("user", user)
    }, [year, quarter, user])

    return (
        <Card className="bg-100 shadow-none border mb-3">
            <Card.Body className="py-0">
                <Row className="g-0 justify-content-between">
                    <Col sm="auto">
                        <Flex alignItems="center">
                            <img src={barChart} width={90} alt="..." className="ms-n4" />
                            <div>
                                <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                                <h4 className="text-primary fw-bold mb-0">
                                    Tea Drop
                                    <span className="text-info fw-medium"> Sales Targets - Management View</span>
                                </h4>
                            </div>
                            <img
                                src={lineChart}
                                width={140}
                                alt="..."
                                className="ms-n4 d-md-none d-lg-block"
                            />
                        </Flex>
                    </Col>
                    <Col md="auto" className="mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                        <Row className="crm__yearPicker g-3 me-2 gy-md-0 h-100 align-items-center">
                            <Form.Select defaultValue={quarter} onChange={(e) => setUser(e.target.value)} size="sm"
                                className="me-2">
                                <option>Select Sales Employee</option>
                                <option value={16}>Richard</option>
                                <option value={15}>Sydney General User</option>
                                <option value={18}>Helen</option>
                                <option value={23}>Fabrizio</option>
                            </Form.Select>
                        </Row>

                        {/* <Row className="crm__yearPicker g-3 gy-md-0 h-100 align-items-center">
                            <DatePicker
                                selected={year}
                                onChange={(_year) => setYear(_year)}
                                className='form-control text-center'
                                dateFormat="yyyy"
                                showYearPicker />
                        </Row> */}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ParamSelector;
