import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Form from 'react-bootstrap/Form';
import {useLocalStore, useSafeQuery} from "@dladio/hooks";
import {useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";

const KanbanHeader = () => {
    const {select, setStore} = useLocalStore('lead-dashboard')

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD,
        body: {
            "name": "b9b6d5381f"
        }
    });

    const onChangeSelection = (event) => {
        setStore('selected-sales-users', event.target.value)
    }

    return (
        <Row style={{backgroundColor: "white"}}
             className="gx-0 shadow-sm kanban-header rounded-2 px-card py-2 mt-2 mb-3">
            <Col className="d-flex align-items-center">
            </Col>
            <Col xs="auto" as={Flex} alignItems="center">
                <Form.Select defaultValue={select('event.target.value')} onChange={onChangeSelection} size="sm"
                             aria-label="Default select example">
                    <option value={''}>Select Sales User</option>
                    {
                        data?.message?.map((item) => (
                            <option value={item?.key}>{item?.text}</option>
                        ))
                    }
                </Form.Select>
            </Col>
        </Row>
    );
};

export default KanbanHeader;
