import React, {useEffect, useState} from 'react';
import Flex from 'components/common/Flex';
import {Card, Col, Row} from 'react-bootstrap';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import DatePicker from "react-datepicker";
import moment from "moment";
import {useLocalStore} from "@dladio/hooks";
import {STORE} from "../../../consts";

const Greetings = () => {
    const [year, setYear] = useState(new Date())
    const [month, setMonth] = useState(new Date())

    const {setStore} = useLocalStore(STORE.KEY_ACC_MGT)

    useEffect(() => {
        setStore(STORE.SELECTED_YEAR_MONTH, {
            year: moment(year).year(),
            month: (moment(month).month() + 1)
        })
    }, [year, month])

    return (
        <Card className="bg-100 shadow-none border mb-3">
            <Card.Body className="py-0">
                <Row className="g-0 justify-content-between">
                    <Col sm="auto">
                        <Flex alignItems="center">
                            <img src={barChart} width={90} alt="..." className="ms-n4"/>
                            <div>
                                <h6 className="text-primary fs--1 mb-0">Welcome to </h6>
                                <h4 className="text-primary fw-bold mb-0">
                                    Helios
                                    <span className="text-info fw-medium"> CRM</span>
                                </h4>
                            </div>
                            <img
                                src={lineChart}
                                width={140}
                                alt="..."
                                className="ms-n4 d-md-none d-lg-block"
                            />
                        </Flex>
                    </Col>
                    <Col md="auto" className="mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                        <Row className="crm__yearPicker g-3 me-2 gy-md-0 h-100 align-items-center">
                            <DatePicker
                                selected={month}
                                onChange={(_month) => setMonth(_month)}
                                className='form-control text-center'
                                dateFormat="MMMM"
                                showTwoColumnMonthYearPicker
                                showMonthYearPicker/>
                        </Row>

                        <Row className="crm__yearPicker g-3 gy-md-0 h-100 align-items-center">
                            <DatePicker
                                selected={year}
                                onChange={(_year) => setYear(_year)}
                                className='form-control text-center'
                                dateFormat="yyyy"
                                showYearPicker/>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default Greetings;
