import { useAppBridgeSelector } from "@dladio/app-bridge"
import { useLocalStore, useSafeQuery } from "@dladio/hooks"
import { useExecMethodApiQuery } from "@dladio/service"
import { EXEC_QUERY_METHOD } from "@dladio/service/dist/const/erp-method.consts"
import { STORE } from "app/consts"
import _ from "lodash"
import { Col, Row } from "react-bootstrap"
import WidgetSectionTitle from "widgets/WidgetSectionTitle"
import OverallSalesByNewAndExisting from "./components/OverallSalesByNewAndExisting/OverallSalesByNewAndExisting"
import OverallSalesDistribution from "./components/OverallSalesDistribution/OverallSalesDistribution"
import QuarterlySalesGuage from "./components/QuarterlySalesGuage/QuarterlySalesGuage"
import StatsCard from "./components/StatsCard"

const CurrentQuarterSummary = () => {

    const { model } = useAppBridgeSelector()
    const { model: localModel } = useLocalStore(STORE.SALES_DASH)

    const year = _.get(localModel, ['selectedYearMonth', 'year'])
    const quarter = _.get(localModel, ['selectedYearMonth', 'quarter'])
    const user = _.get(model, ['ui', 'currentUser', 'sap_id'])

    const { data } = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "00762099e3",
            quarter: quarter,
            year: year,
            user: user
        },
    });

    const chartData = !_.isEmpty(data) ? _.get(data, 'message') : null;

    const newTraget = _.get(chartData, "newTraget") ?? 0;
    const oldTraget = _.get(chartData, "oldTraget") ?? 0;
    const newAchievement = _.get(chartData, "newAchievement") ?? 0;
    const oldAchievement = _.get(chartData, "oldAchievement") ?? 0;

    const getQuarterName = () => {

        let value = ''

        switch (quarter) {
            case 1:
                value = '3rd'
                break;
            case 2:
                value = '4th'
                break;
            case 3:
                value = '1st'
                break;
            case 4:
                value = '2nd'
                break;
        }

        return value
    }

    return (
        <Row className={"g-3 mb-3"}>
            <Col lg={12}>
                <WidgetSectionTitle
                    icon="list"
                    title={"Current Quarter Performance"}
                    r
                    subtitle={getQuarterName() + " Quarter - " + + year + "/" + (year + 1)}
                    transform="shrink-2"
                    className="mb-2"
                />
            </Col>

            <Col lg={6}>
                <StatsCard varient="danger" title={getQuarterName() + " Quarter Target"}
                    value={(newTraget + oldTraget)} />
            </Col>

            <Col lg={6}>
                <StatsCard varient="secondary" title={getQuarterName() + " Quarter Achievement"}
                    value={newAchievement + oldAchievement} />
            </Col>

            <Col lg={6}>
                <QuarterlySalesGuage data={chartData}></QuarterlySalesGuage>
            </Col>

            <Col lg={6}>
                <OverallSalesDistribution />
            </Col>

            <Col lg={12}>
                <OverallSalesByNewAndExisting />
            </Col>
        </Row>
    )
}

// 00762099e3

export default CurrentQuarterSummary