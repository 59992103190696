import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import {store} from './store/store';
import './app/styles/custom.css'
import {AppBridgeProvider} from "@dladio/app-bridge";

const App = () => {
    return (
        <Provider store={store}>
            <AppBridgeProvider apiKey={'custom-ar-dashboard'}>
                <Router basename={process.env.PUBLIC_URL}>
                    <Layout/>
                </Router>
            </AppBridgeProvider>
        </Provider>
    );
};

export default App;
