import email from "../../../../assets/img/icons/email.svg";
import social from "../../../../assets/img/icons/social.svg";
import call from "../../../../assets/img/icons/call.svg";
import other from "../../../../assets/img/icons/other.svg";

export const STAT_DATA = [
    {
        id: 1,
        title: 'Total Business Partners',
        amount: 266,
        target: '2500 vs 2683',
        icon: 'phone',
        caret: 'caret-up',
        color: 'primary',
        caretColor: 'success',
        data: [220, 230, 150, 175, 200, 170, 70, 160]
    },
    {
        id: 2,
        title: 'Current Month BP (September)',
        amount: 1,
        target: '1635 vs 863',
        icon: 'user',
        caret: 'caret-up',
        color: 'info',
        caretColor: 'success',
        data: [90, 160, 150, 120, 230, 155, 220, 240]
    },
    {
        id: 3,
        title: 'Last Quarter BP',
        amount: 11,
        target: '1423 vs 256',
        icon: 'bolt',
        caret: 'caret-down',
        color: 'success',
        caretColor: 'danger',
        data: [200, 150, 175, 130, 150, 115, 130, 100]
    },
    {
        id: 4,
        title: 'Current Year BP (2022)',
        amount: 50,
        target: '1423 vs 256',
        icon: 'bolt',
        caret: 'caret-down',
        color: 'success',
        caretColor: 'danger',
        data: [200, 150, 175, 130, 150, 115, 130, 100]
    },
    {
        id: 5,
        title: 'New Leads',
        amount: 16,
        target: '1423 vs 256',
        icon: 'bolt',
        caret: 'caret-down',
        color: 'success',
        caretColor: 'danger',
    },
    {
        id: 5,
        title: 'Overdue Tasks',
        amount: 24,
        target: '1423 vs 256',
        icon: 'circle',
        caret: 'caret-up',
        color: 'warning',
        caretColor: 'danger',
    }
];

export const GRADING_SUMMARY_DATA = [
    {
        id: 1,
        title: 'Bronze',
        target: '5200 vs 1052',
        img: email,
        amount: 34
    },
    {
        id: 2,
        title: 'Silver',
        target: '5623 vs 4929',
        img: social,
        amount: 30
    },
    {
        id: 3,
        title: 'Platinum',
        target: '2535 vs 1486',
        img: call,
        amount: 50
    },
    {
        id: 4,
        title: 'Gold',
        target: '2535 vs 1486',
        img: call,
        amount: 27
    },
    {
        id: 5,
        title: 'Ungraded',
        target: '2535 vs 1486',
        img: call,
        amount: 125
    },
    {
        id: 6,
        title: 'Other',
        target: '256 vs 189',
        img: other,
        amount: 53
    }
];

export const totalSale = {
    lastMonth: [50, 80, 60, 80, 65, 90, 130, 90, 30, 40, 30, 70],
    previousYear: [110, 30, 40, 50, 80, 70, 50, 40, 110, 90, 60, 60]
};

export const salesStat = [
    {
        title: 'Orders',
        amount: '15,450',
        subAmount: '13,675',
        type: 'standard',
        percent: 21.8,
        className: 'border-200 border-bottom border-end pb-4'
    },
    {
        title: 'Items sold',
        amount: '1,054',
        subAmount: '13,675',
        type: 'warning',
        percent: 21.8,
        className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3'
    },
    {
        title: 'Refunds',
        amount: '$145.65',
        subAmount: '13,675 ',
        type: 'up',
        percent: 21.8,
        className:
            'border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3'
    },
    {
        title: 'Gross sale',
        amount: '$100.26',
        subAmount: '$109.65 ',
        type: 'down',
        percent: 21.8,
        className:
            'border-200 border-md-200 border-bottom border-md-bottom-0 border-md-end pt-4 pb-md-0 ps-3 ps-md-0'
    },
    {
        title: 'Shipping',
        amount: '$365.53',
        subAmount: '13,675 ',
        type: 'up',
        percent: 21.8,
        className: 'border-200 border-md-bottom-0 border-end pt-4 pb-md-0 ps-md-3'
    },
    {
        title: 'Processing',
        amount: '861',
        subAmount: '13,675 ',
        type: 'pending',
        percent: 21.8,
        className: 'pb-0 pt-4 ps-3'
    }
];

export const arData = [
    {
        id: 1,
        title: '0 - 31',
        amount: 43230,
        variant: 'success'
    },
    {
        id: 2,
        title: '31 - 60',
        amount: 18268,
        variant: 'soft-success'
    },
    {
        id: 3,
        title: '61 - 90',
        amount: 16279,
        variant: 'primary'
    },
    {
        id: 4,
        title: '91 - 120',
        amount: 12661,
        variant: 'warning'
    },
    {
        id: 5,
        title: '120+',
        amount: 12661,
        variant: 'danger'
    }
];