import classNames from 'classnames';
import AppContext from 'context/Context';
import React, {useContext, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={'container-fluid pt-2'}>
      {/*{(navbarPosition === 'vertical' || navbarPosition === 'combo') && (*/}
      {/*  <NavbarVertical />*/}
      {/*)}*/}
      <div className={classNames('content', { 'pb-0': isKanban })}>
        <Outlet />
        {/*{!isKanban && <Footer />}*/}
      </div>
    </div>
  );
};

export default MainLayout;
