import React from "react";
import CountUp from "react-countup";
import StatCard from "./components/statcard-component";
import classNames from "classnames";
import ArPieChart from "./components/ar-pie-chart";
import BasicBarChart from "./components/ar-basic-bar-chart";
import {useSafeQuery} from "@dladio/hooks";
import DashboardHeader from "./components/dashboard-header";
import {dispatchAction} from "@dladio/app-bridge";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {Card, Col, Row, Table} from "react-bootstrap";
import {useExecMethodApiQuery} from "@dladio/service";
import {onClickBp, onClickInvoiceNo} from "./navigation-handlers";

let data = {
    "stat_line1": [
        {
            "title": "Total Outstanding",
            "prefix": "$",
            "query": '0b8624900f',
            "link": "3ec1614203"
        },
        {
            "title": "Aged Receivables",
            "prefix": "$",
            "query": '8a59237b4b',
            "link": "e5da96d3ea"
        },
        {
            "title": "Unreconciled Lines",
            "prefix": "",
            "query": '3f5f80688a',
            "link": "fbb00cdfb6"
        },
        {
            "title": "Today's Tasks",
            "prefix": "",
            "query": '27c76e2c7d',
            "link": "6501d9bbba"
        },
    ],
    "pie_chart": {
        "query": '2889bea370'
    },
    "bar_cart": {
        "query": 'fbe868c0c6'
    },
    "stats_lines": [
        {
            "title": 'Receivables By Business Partner Group ',
            "lines":
                [
                    {
                        "title": "Wholesale",
                        "prefix": "$",
                        "query": "4c76dd1529",
                        "link": "10b19fcc38"
                    },
                    {
                        "title": "Agents & Distributors",
                        "prefix": "$",
                        "query": "073db058a5",
                        "link": "440dc5bc3c"
                    },
                    {
                        "title": "International",
                        "prefix": "$",
                        "query": "46e438f021",
                        "link": "ec1025171f"
                    },
                    {
                        "title": "Bunnings",
                        "prefix": "$",
                        "query": "5e9abc2eb6",
                        "link": "81cf3c4cb2"
                    }
                ]

        },
        {
            "title": "Receivables By Payment Term",
            "lines": [
                {
                    "title": "Ezi Debit",
                    "prefix": "$",
                    "query": "73b28c0f52",
                    "link": "8bafadf0a6"
                },
                {
                    "title": "Pre Paid",
                    "prefix": "$",
                    "query": "24e78ac8ee",
                    "link": "8ccb45929f"
                },
                {
                    "title": "COD",
                    "prefix": "$",
                    "query": "a23b61255a",
                    "link": "0ad60e20cd"
                },
                {
                    "title": "Online",
                    "prefix": "$",
                    "query": "45deacb227",
                    "link": "46ff92a333"
                }
            ]
        }
    ],
    "receivables_overdue": [
        {
            "range": "1 - 30",
            "query": "47ef6886ce",
            "prefix": "$",
            "link": "5420a1d051"
        },
        {
            "range": "31 - 60",
            "query": "c7015f7834",
            "prefix": "$",
            "link": "75c52db75e"
        },
        {
            "range": "61 - 90",
            "query": "2de645e469",
            "prefix": "$",
            "link": "12a8fbbcca"
        },
        {
            "range": "91 - 120",
            "query": "1e9f00d0c1",
            "prefix": "$",
            "link": "c598e48195"
        }
    ],
    "high_risk_customers": {
        "title": 'High Risk Customers',
        "query": '0a7b0e46ff',
        "table_headers": [
            {col_name: "Customer Name", key: "name", onClick: onClickBp},
            {col_name: "Invoice Number", key: "invo_no", onClick: onClickInvoiceNo},
            {col_name: "Amount Due", key: "amount"},
            {col_name: "Days Overdue", key: "overdue_dates"}
        ]
    },
    "bps_on_credit_Hold": {query: "a2b66fbae0"},
    "invoices_due_days": {
        "title": 'Invoices Due Next 5 Days',
        "query": 'a7d436fcb6',
        "buttons": [{
            label: "View",
            action: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: '/app/page/e77eb9f750'
                })
            }
        }],
        "table_headers": [
            {col_name: "Customer Name", key: "name", onClick: onClickBp},
            {col_name: "Invoice Number", key: "invo_no", onClick: onClickInvoiceNo},
            {col_name: "Amount due", key: "amount"}
        ]
    }
}
const CustomArDashboard = () => {
    return (<>
            <Row className={'ps-2 pe-2'}>
                <DashboardHeader/>
            </Row>
            <Row className={'ps-2 pe-2'}>
                <div className={"d-flex flex-row gap-3 p-0 m-0 align-content-between"}>
                    {
                        _.get(data, 'stat_line1').map((line) => (
                            <div className={"flex-1"}>
                                <StatCard title={_.get(line, 'title')} query={_.get(line, 'query')}
                                          prefix={_.get(line, 'prefix')} link={_.get(line, 'link')}/>
                            </div>
                        ))
                    }
                </div>
            </Row>
            <Row className="mt-3 ps-2 pe-2">
                <Col lg={6} className={'pe-0 m-0 ps-0 pe-2'}>
                    <ArPieChart query={_.get(data, 'pie_chart.query')}/>
                </Col>
                <Col lg={6} className={'pe-0 m-0 ps-2'}>
                    <Row className={'p-0 m-0'}>
                        <BasicBarChart query={_.get(data, 'bar_cart.query')}/>
                    </Row>

                </Col>
            </Row>
            <Row className={'ps-2 pe-2'}>
                {
                    _.get(data, 'stats_lines').map((statLine) => (
                        <StatBar data={statLine}/>
                    ))
                }
            </Row>
            <Row className={'ps-2 pe-2'}>
                <OverdueStatBar data={_.get(data, 'receivables_overdue')}/>
            </Row>
            <Row className={'ps-2 pe-2'}>
                <CustomTable data={_.get(data, 'high_risk_customers')}/>
            </Row>
            <Row className={'ps-2 pe-2'}>
                <Col lg={6} className={'pe-0 m-0 ps-0 pe-2'}>
                    <BpsOnCreditHold data={_.get(data, 'bps_on_credit_Hold')}/>
                </Col>
                <Col lg={6} className={'pe-0 m-0 ps-2'}>
                    <CustomTable data={_.get(data, 'invoices_due_days')}/>
                </Col>
            </Row>
        </>
    )
}

const StatBar = ({data}) => {
    return (
        <Card className={'w-100 pb-4 px-4 mt-3'}>
            <div className={'py-3'}>
                {_.get(data, 'title')}
            </div>

            <div className={"d-flex flex-row gap-3 align-content-between"}>
                {
                    _.get(data, 'lines')?.map((line) => (
                            <div className={"flex-1"}>
                                <StatCard className={`border shadow-none ${_.get(line, 'link') ? 'cursor-pointer' : ''} `}
                                          title={_.get(line, 'title')}
                                          query={_.get(line, 'query')} link={_.get(line, 'link')}
                                          prefix={_.get(line, 'prefix')}/>
                            </div>
                        )
                    )
                }
            </div>
        </Card>
    )
}

const CustomTable = ({data}) => {

    const {data: columns} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: _.get(data, ["query"]),
        },
    });

    const buttons = _.get(data, 'buttons')

    let resolvingData = _.get(columns, ["message"]) ?? []

    return (<Card className={'w-100 pb-4 px-2 mt-3'} style={{height: "60vh", overflow: "hidden"}}>

        <div style={{display: "flex", direction: "row", justifyItems: "center"}}>
            <div className={'py-4 px-3'}>
                {_.get(data, 'title')}
            </div>
            {buttons && (buttons?.map((button) => (
                <button onClick={_.get(button, 'action')} type="button"
                        className={'my-4 mx-2 btn-sm cursor-pointer btn btn-primary'}>
                    {_.get(button, 'label')}
                </button>)
            ))}
        </div>

        <Table responsive>
            <thead>
            <tr>
                {
                    _.get(data, 'table_headers')?.map((line) => <th scope="col">{line?.col_name}</th>)
                }
            </tr>
            </thead>
            <tbody>

            {
                resolvingData?.map((row) => (
                    <tr className={'hover-bg-200'}>
                        {_.get(data, 'table_headers')?.map((line) => (
                                <td scope="col" className={`${_.get(line, 'onClick') ? 'cursor-pointer' : ''}`} onClick={
                                    _.get(line, 'onClick') ? () => _.get(line, 'onClick')(row, line) : () => {
                                    }
                                }>
                                    {row[line?.key]}
                                </td>
                            )
                        )
                        }
                    </tr>
                ))
            }
            </tbody>
        </Table>
    </Card>)

}

const OverdueStatBar = ({data}) => (
    <Card className={'w-100 pb-4 px-4 mt-3'}>
        <div className={'py-3'}>
            Receivable By Days Overdue
        </div>

        <div className={"d-flex flex-row gap-3 align-content-between"}>
            {
                data?.map((line) => (
                    <div className={"flex-1"}>
                        <OverdueStatBarCard className={'border shadow-none'} range={_.get(line, 'range')}
                                            query={_.get(line, 'query')} prefix={_.get(line, 'prefix')}
                                            link={_.get(line, 'link')}
                        />
                    </div>
                ))
            }
        </div>
    </Card>
)


const OverdueStatBarCard = ({query, range, prefix, link}) => {

    const {data: value} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: query,
        },
    });

    let resolvingData = _.get(value, "message.value") || 0

    const onClickHandler = () => {
        if (link) {
            dispatchAction({
                action: 'navigate',
                payload: `/app/page/${link}`
            })
        }
    }


    return (
        <div className={`border rounded p-5 ${link ? 'cursor-pointer' : ''}`} onClick={onClickHandler}>
            <div className={'w-100 h1 text-xl-center justify-content-center align-content-center d-flex flex-row'}>
                {range}
            </div>
            <div
                className={classNames(
                    'display-4 fs-4 fw-normal font-sans-serif d-flex justify-content-center align-content-center'
                )}
            >
                <CountUp
                    start={0}
                    end={resolvingData}
                    duration={2.75}
                    suffix={""}
                    prefix={prefix || ''}
                    separator=","
                    decimals={2 ? 2 : 0}
                    decimal="."
                />
            </div>
        </div>
    )
}

const BpsOnCreditHold = ({data}) => {

    const {data: columns} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: _.get(data, "query"),
        },
    });

    let resolvingData = _.get(columns, ["message"]) ?? []

    const navigationHandler = (cardCode) => {
        dispatchAction({
            action: 'navigate',
            payload: `/app/page/cea858702d?id=${cardCode}`
        })
    }

    return (<div className={'h-100 pt-3'}>
        <Card className={'h-100 py-4 px-4'} style={{height: "60vh", overflow: "hidden"}}>
            <div>
                <p>
                    BPs On Credit Hold
                </p>
            </div>
            <div style={{height: "45vh", overflowY: "scroll", overflowX: "hidden"}}>
                <ul className="list-group list-group-flush">
                    {resolvingData?.map((line) => <li className="list-group-item hover-bg-200 cursor-pointer"
                                                      onClick={() => navigationHandler(_.get(line, 'card_code'))}>{_.get(line, "name")}</li>)}
                </ul>
            </div>
        </Card>
    </div>)
}

export default CustomArDashboard