import React from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {BarChart, LineChart} from 'echarts/charts';
import {getColor} from 'helpers/utils';
import {GridComponent, LegendComponent, TitleComponent, TooltipComponent} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    BarChart
]);

const tooltipFormatter = params => {
    return `<div class="card">
              <div class="card-header bg-light py-2">
                <h6 class="text-600 mb-0">${params[0].axisValue}</h6>
              </div>
            <div class="card-body py-2">
              <h6 class="text-600 fw-normal">
                <span class="dot me-1 d-inline-block bg-primary"></span>
                Revenue: 
                <span class="fw-medium">$${params[0].data}</span>
              </h6>
              <h6 class="text-600 mb-0 fw-normal"> 
                <span class="dot me-1 d-inline-block bg-warning"></span>
                Gross Profit: 
                <span class="fw-medium">$${params[1].data}</span>
              </h6>
            </div>
          </div>`;
};

const getOptions = (sales, profits, labels) => ({
    color: getColor('white'),
    tooltip: {
        trigger: 'axis',
        padding: 0,
        backgroundColor: 'transparent',
        borderWidth: 0,
        transitionDuration: 0,
        axisPointer: {
            type: 'none'
        },
        formatter: tooltipFormatter
    },
    xAxis: {
        type: 'category',
        data: labels,
        axisLabel: {
            color: getColor('600'),
            align: 'left',
            fontSize: 11,
            padding: [0, 0, 0, 5],
            showMaxLabel: false
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        boundaryGap: true
    },
    yAxis: {
        position: 'left',
        axisPointer: {type: 'none'},
        axisTick: 'none',
        splitLine: {
            show: false
        },
        axisLine: {
            show: false
        },
        axisLabel: {
            show: false
        }
    },
    series: [
        {
            type: 'bar',
            name: 'Revenue',
            stack: 'StackName',
            data: sales,
            lineStyle: {
                color: getColor('primary')
            },
            label: {
                normal: {
                    show: true,
                    position: 'top'
                }
            },
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                color: getColor('100'),
                borderColor: getColor('300'),
                borderWidth: 1
            },
            emphasis: {
                itemStyle: {
                    color: getColor('primary')
                }
            }
        },
        {
            type: 'line',
            name: 'CustomerRetentionSymmary Goal',
            data: profits,
            symbol: 'circle',
            symbolSize: 6,
            animation: true,
            itemStyle: {
                color: getColor('warning')
            }
        }
    ],
    grid: {right: 5, left: 5, bottom: '8%', top: '5%'}
});

const SalesVsGrossProfitChart = ({sales, profits, labels}) => {

    return (
        <ReactEChartsCore
            echarts={echarts}
            option={getOptions(sales, profits, labels)}
            style={{height: '20rem'}}/>
    );
};

export default SalesVsGrossProfitChart;
