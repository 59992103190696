import SalesTargetChart from "./sales-target-chart/SalesTargetChart";
import SalesTargetTable from "./sales-target-table/SalesTargetTable";

export const data = [
    ['month', 'Sales Target', 'Achieved'],
    ['January', 101, 10],
    ['February', 83, 73],
    ['March', 86, 62],
    ['April', 72, 53],
    ['May', 80, 50],
    ['June', 50, 70],
    ['July', 80, 90],
    ['August', 80, 90],
    ['September', 80, 90],
    ['October', 80, 90],
    ['November', 80, 90],
    ['December', 80, 90],

];

const SalesTarget = () => {
    return (
        <>
            <div>
                <SalesTargetChart data={data}/>
            </div>
            <div className={'pt-2'}>
                <SalesTargetTable/>
            </div>
        </>

    )
}

export default SalesTarget