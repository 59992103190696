import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import Background from 'components/common/Background';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';

const  StatCard = ({ title, value}) => {

    return (
        <Card className={classNames( 'overflow-hidden m-0')} >
            {/*<Background image={image} className="bg-card" />*/}
            <Card.Body className="position-relative">
                <h6>
                    {title}
                </h6>
                <div
                    className={classNames(
                        'display-4 fs-4 fw-normal font-sans-serif'
                    )}
                >
                    <CountUp
                        start={0}
                        end={value}
                        duration={2.75}
                        suffix={""}
                        prefix={""}
                        separator=","
                        decimals={2 ? 2 : 0}
                        decimal="."
                    />
                </div>
                <Link to={""} className="fw-semi-bold fs--1 text-nowrap">
                    {/*{linkText}*/}
                    <FontAwesomeIcon
                        icon="angle-right"
                        className="ms-1"
                        transform="down-1"
                    />
                </Link>
            </Card.Body>
        </Card>
    );
};

StatCard.propTypes = {
    stat: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
        decimal: PropTypes.bool,
        suffix: PropTypes.string,
        prefix: PropTypes.string,
        valueClassName: PropTypes.string,
        linkText: PropTypes.string,
        link: PropTypes.string,
        badgeText: PropTypes.string,
        badgeBg: PropTypes.string,
        image: PropTypes.string,
        className: PropTypes.string
    })
};

export default StatCard;
