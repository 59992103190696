import React from 'react';
import KanbanColumnHeader from './KanbanColumnHeader';
import TaskCard from './TaskCard';
import {useLocalStore, useSafeQuery} from "@dladio/hooks";
import {useDerive, useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import classNames from "classnames";

const KanbanColumn = ({type}) => {
    const {select, setStore} = useLocalStore('lead-dashboard')
    const {data} = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD,
            body: {
                "name": "7b89bb2899",
                "id": type,
                "sap_id": select('selected-sales-users') ? select('selected-sales-users') : ''
            }
        },
        path: 'message'
    });

    return (
        <div className={classNames('kanban-column')} style={{minWidth: '25vw'}}>
            <KanbanColumnHeader id={1} title={type} itemCount={5} sapId={select('selected-sales-users')}/>
            <div className={'flex-column flex py-1 pb-3 px-3'} style={{backgroundColor: '#f9fafe'}}>
                {
                    _.isArray(data?.message) && data?.message?.map((item) => (
                        <TaskCard data={item}/>
                    ))
                }
            </div>
        </div>
    );
};


export default KanbanColumn;
