import { useLocalStore } from "@dladio/hooks";
import { STORE } from "app/consts";
import _ from "lodash";
import { Row } from "react-bootstrap";
import DashboardHeader from "./components/Header/Header";
import ParamSelector from "./components/ParamSelector/ParamSelector";

const ActivityManagementDashboard = () => {

    return (
        <>
            <Row className={"g-3"}>
                <ParamSelector />
            </Row>
            <Row className={"g-3"}>
                <DashboardHeader />
            </Row>
        </>
    );
}

export default ActivityManagementDashboard