import React from 'react';
import KanbanHeader from './KanbanHeader';
import KanbanContainer from './KanbanContainer';

const Kanban = () => {
    return (
        <>
            <KanbanHeader/>
            <KanbanContainer/>
        </>
    );
};

export default Kanban;
