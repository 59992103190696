import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {LineChart} from 'echarts/charts';
import {GridComponent, LegendComponent, TitleComponent, TooltipComponent} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import {getColor} from 'helpers/utils';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent
]);

const CustomerGradingSummaryChart = ({data}) => {
    const chartRef = useRef(null)
    const getOption = () => ({
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            textStyle: {color: getColor('dark')},
            borderWidth: 1,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        },

        series: [
            {
                type: 'pie',
                radius: window.innerWidth < 530 ? '45%' : '60%',
                center: ['50%', '50%'],
                data: data,
                label: {
                    position: 'outer',
                    alignTo: 'labelLine',
                    bleedMargin: 5,
                    color: getColor('gray-700')
                },
                left: '5%',
                right: '5%',
                top: 0,
                bottom: 0
            }
        ]
    })

    const updateDimensions = () => {
        if (window.innerWidth < 530) {
            chartRef.current.getEchartsInstance().setOption({
                series: [{radius: '45%'}]
            });
        } else
            chartRef.current.getEchartsInstance().setOption({
                series: [{radius: '60%'}]
            });
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div className="position-relative py-2">
            <ReactEChartsCore
                echarts={echarts}
                option={getOption()}
                ref={chartRef}
                style={{height: '18.4rem'}}
            />
        </div>
    );
};

CustomerGradingSummaryChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.array)
};

export default CustomerGradingSummaryChart;
