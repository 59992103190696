import React from 'react';
import WeeklySales from './WeeklySales';
import { Row, Col, Button } from 'react-bootstrap';
import {
  marketShare,
  totalOrder,
  totalSales,
  weeklySalesData,
  weather,
  products,
  storageStatus,
  files,
  users,
  topProducts,
  runningProjects
} from 'data/dashboard/default';
import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';
import TotalSales from './TotalSales';
import RunningProjects from './RunningProjects';
import StorageStatus from './StorageStatus';
import SpaceWarning from './SpaceWarning';
import BestSellingProducts from './BestSellingProducts';
import SharedFiles from './SharedFiles';
import ActiveUsers from './ActiveUsers';
import BandwidthSaved from './BandwidthSaved';
import TopProducts from './TopProducts';
import Weather from './Weather';

const Dashboard = () => {

  const onClickHandler = () => {
    window.open('https://teadrop.sharepoint.com/:f:/s/Operations/Eky70hyLMTtBuRBxjOenlxYBpAWwnlsP5wh2bKWOSCwtrw?e=ktAi8B', '_blank').focus();
  }

  return (
    <>
      <Button onClick={onClickHandler}>View HACCP Documents</Button>
    </>
  );
};

export default Dashboard;
