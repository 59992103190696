import 'helpers/initFA';
import React from 'react';
import App from './App';
import Main from './Main';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('main');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
<React.StrictMode>
  <Main>
    <App />
  </Main>
</React.StrictMode>);
