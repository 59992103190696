import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { formStore, localStore } from '@dladio/hooks';
import { erpMethodApi, erpResourceApi, googleMapApi, msGraphApi } from '@dladio/service';
import { storeConnector } from '@dladio/app-bridge';

export const store = configureStore({
    reducer: combineReducers({
        localStore: localStore,
        formStore: formStore,
        [storeConnector.reducerPath]: storeConnector.reducer,
        [erpMethodApi.reducerPath]: erpMethodApi.reducer,
        [erpResourceApi.reducerPath]: erpResourceApi.reducer,
        [msGraphApi.reducerPath]: msGraphApi.reducer,
        [googleMapApi.reducerPath]: googleMapApi.reducer
    }),
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        })
        .concat(erpMethodApi.middleware)
        .concat(erpResourceApi.middleware)
        .concat(googleMapApi.middleware)
        .concat(msGraphApi.middleware)
    }
});