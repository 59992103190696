import { getDateMeta } from '@fullcalendar/react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import _ from 'lodash';
import React from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';

const QuarterlySalesGuageForSummary = ({ data }) => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const getOptions = () => ({
        series: [
            {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                center: ['50%', '75%'],
                radius: '100%',
                min: 0,
                max: 100,
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        width: 20,
                        color: [
                            [0.25, '#FF6E76'],
                            [0.5, '#FDDD60'],
                            [0.75, '#58D9F9'],
                            [1, '#7CFFB2']
                        ]
                    }
                },
                pointer: {
                    icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                    length: '12%',
                    width: 20,
                    offsetCenter: [0, '-60%'],
                    itemStyle: {
                        color: 'auto'
                    }
                },
                axisTick: {
                    length: 12,
                    lineStyle: {
                        color: 'auto',
                        width: 2
                    }
                },
                splitLine: {
                    length: 20,
                    lineStyle: {
                        color: 'auto',
                        width: 5
                    }
                },
                axisLabel: {
                    color: '#464646',
                    fontSize: 20,
                    distance: -60,
                    rotate: 'tangential',
                    formatter: function (value) {
                        // if (value === 10) {
                        //     return "";
                        // } else if (value === 7.5) {
                        //     return '';
                        // } else if (value === 5) {
                        //     return '';
                        // } else if (value === 2.5) {
                        //     return '';
                        // }
                        return '';
                    }
                },
                title: {
                    offsetCenter: [0, '-10%'],
                    fontSize: 20
                },
                detail: {
                    fontSize: 30,
                    offsetCenter: [0, '-35%'],
                    valueAnimation: true,
                    formatter: function (value) {
                        return formatter.format(getAchievement())
                    },
                    color: 'auto'
                },
                data: [
                    {
                        value: getData(),
                        name: getMoreToGo()
                    }
                ]
            }
        ]
    });

    const getData = () => {
        let target = getTarget()
        let achievement = getAchievement()

        return (achievement / target) * 100
    }

    const getTarget = () => {
        return (+data?.oldTraget || 0) + (+ data?.newTraget || 0)
    }

    const getAchievement = () => {
        return (+data?.oldAchievement || 0) + (+ data?.newAchievement || 0)
    }

    const getMoreToGo = () => {
        let value = +getTarget() - +getAchievement()

        if (value > 0)
            return formatter.format(Number(value)) + " needed"
        else
            return 'Overdone ' + formatter.format(Number(value) * -1)
    }

    //{"newTraget":36000,"oldTraget":173952,"oldAchievement":37242.26}
    return (
        <>
            <Row>
                <Col lg={6}>
                    <NumberDisplay value={getTarget()} title={'Target'} />
                </Col>
                <Col lg={6}>
                    <NumberDisplay value={getAchievement()} title={'Achievement'} />
                </Col>
                <Col lg={12}>
                    {!_.isEmpty(data) ? <ReactEChartsCore
                        echarts={echarts}
                        option={getOptions()}
                        style={{ height: '25rem' }}
                    /> : (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )}
                </Col>
            </Row>
        </>
    );
};

const NumberDisplay = ({ value, title }) => {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    return (
        <Card className="h-100 py-2" bg={'secondary'} text={'white'}>
            <Card.Body>
                <Card.Title as="div" style={{ 'textAlign': 'center' }}>{formatter.format(Number(value))}</Card.Title>
                <Card.Text style={{ 'textAlign': 'center' }}>
                    {title}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default QuarterlySalesGuageForSummary