import React from "react";
import StatCard from "./components/statcard-component";
import ArPieChart from "./components/ar-pie-chart";
import {useSafeQuery} from "@dladio/hooks";
import DashboardHeader from "./components/dashboard-header";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {useExecMethodApiQuery} from "@dladio/service";

const ArDashboard = () => {

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: "76454ead86",
        },
    });

    return (
        <>
            <div className={"d-flex flex-row"}>
                <div className={"flex-1"}>
                    <DashboardHeader/>
                </div>
            </div>
            <div className={"d-flex flex-column gap-3"}>
                <div className={"d-flex flex-row gap-3 align-content-between"}>
                    <div className={"flex-1"}>
                        <StatCard title={"Wholesale"} value={data?.message?.wholesaleAmount}/>
                    </div>
                    <div className={"flex-1"}>
                        <StatCard title={"International"} value={data?.message?.intAmount}/>
                    </div>
                    <div className={"flex-1"}>
                        <StatCard title={"Bunnings"} value={data?.message?.bunningAmount}/>
                    </div>
                </div>
                <div className={"d-flex flex-row gap-3"}>
                    <div className={"flex-1"}>
                        <StatCard title={"Distributors / Agents"} value={data?.message?.agentAmount}/>
                    </div>
                    <div className={"flex-1"}>
                        <StatCard title={"South Melbourne Market"} value={data?.message?.smmAmount}/>
                    </div>
                    <div className={"flex-1"}>
                        {/*<StatCard/>*/}
                    </div>
                </div>
            </div>
            <div className={"mt-3 d-flex flex-row"}>
                <div className={"flex-1"}>
                    <ArPieChart data={data}/>
                </div>
            </div>
        </>
    )
}

export default ArDashboard