import React, {useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {STAT_DATA} from "./data";
import SoftBadge from "../../../../components/common/SoftBadge";
import CountUp from "react-countup";
import {useLocalStore, useSafeQuery} from "@dladio/hooks";
import {useExecMethodApiQuery} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import _ from 'lodash'
import {QUERIES, STORE} from "../../../consts";
import {CURRENT_MONTH, CURRENT_YEAR} from "../../../utils/current-date";

export const StatsItem = ({title, config}) => {
    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {...config},
    });

    let resolvingValue = _.get(data, ["message", "value"]) ?? 0

    return (<Card className="h-100 py-2">
        <Card.Body>
            <Row className="flex-between-center">
                <Col className="d-md-flex d-lg-block flex-between-center">
                    <h6 className="mb-md-0 mb-lg-2">{title}</h6>
                    <SoftBadge bg="success" pill>
                        <FontAwesomeIcon icon="caret-up"/> 61.8%
                    </SoftBadge>
                </Col>
                <Col xs="auto">
                    <h4 className="fs-3 fw-normal text-700">
                        <CountUp
                            start={0}
                            end={resolvingValue}
                            duration={2.75}
                            decimal="."/>
                    </h4>
                </Col>
            </Row>
        </Card.Body>
    </Card>);
};

const BpStats = () => {
    const {select} = useLocalStore(STORE.KEY_ACC_MGT)
    let date = select(STORE.SELECTED_YEAR_MONTH)
    let selectedYear = !_.isEmpty(date) ? date.year : CURRENT_YEAR;
    let selectedMonth = !_.isEmpty(date) ? date.month : CURRENT_MONTH

    let statQueries = [
        {
            title: "Total Business Partners",
            config: {
                name: QUERIES.GET_TOTAL_BP_COUNT,
                user: 16
            }
        },
        {
            title: "Current Month BP (September)",
            config: {
                name: QUERIES.GET_CURRENT_BP_COUNT_FOR_THE_MONTH,
                user: 16,
                year: selectedYear,
                month: 9
            }
        },
        {
            title: "Last Quarter BP",
            config: {
                name: QUERIES.GET_CURRENT_BP_COUNT_FOR_LAST_QUARTER,
                user: 16,
                year: selectedYear,
                month: selectedMonth
            }
        },
        {
            title: "Current Year BP (2022)",
            config: {
                name: QUERIES.GET_CURRENT_BP_COUNT_FOR_THE_YEAR,
                user: 16,
                year: selectedYear,
                month: selectedMonth
            }
        },
        {
            title: "Overdue Tasks",
            config: {
                name: QUERIES.GET_OVERDUE_TASK_COUNT,
                user: 16,
                year: selectedYear,
                month: selectedMonth
            }
        },
        {
            title: "Business Partner Dollar Value",
            config: {
                name: QUERIES.GET_BP_DOLLAR_VALUE,
                user: 16,
                year: selectedYear,
                month: selectedMonth
            }
        },
    ]
    return (<Row className={"g-3"}>
        {statQueries.map((line, index) => (<Col key={index} lg={4}>
            <StatsItem {...line}/>
        </Col>))}


    </Row>);
};

export default BpStats;
